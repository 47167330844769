import { FC, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import HistorySideNav from '../../components/historySideNav/HistorySideNav';
import HistorySideDrawer from '../../components/historySideDrawer';
import Round from '../../components/round/Round';

import { RootState } from '../../redux/store';
import {
  clearEmployeeSearch,
  searchEmployees
} from '../../redux/actions/Employee.action';
import { getEmployeeRoundHistory } from '../../redux/actions/Report.action';
import {
  getAllQuestions,
  getTemplateMaster
} from '../../redux/actions/Template.action';
import { clearSelectedEmployee } from '../../redux/actions/User.action';

import { Employee, RoundHistory } from '../../config/interfaces';

interface ValidationProfileProps {
  employeeRoundHistory?: RoundHistory;
  selectedEmployee?: Employee;
}

const ValidationProfile: FC<ValidationProfileProps> = ({
  employeeRoundHistory,
  selectedEmployee
}) => {
  const dispatch = useDispatch();

  const roundCount = employeeRoundHistory?.length ?? 0;

  const [selectedTab, setSelectedTab] = useState<string>('');

  const navigate = useNavigate();

  useEffect(() => {
    setSelectedTab(roundCount === 0 ? '' : 'pastRounds');
  }, [employeeRoundHistory]);

  useEffect(() => {
    if (selectedEmployee) {
      dispatch(
        getEmployeeRoundHistory({
          employeeId: selectedEmployee.hcaid,
          roundingTypes: ['validation']
        })
      );
      /**
       *  TODO: This should really call
       * getActiveTemplates({
       *   archived: false,
       *   condensed: true,
       *   roundingType: validationType === 'csc' ? 'csrn' : ['employee', 'cned']
       * })
       */
      dispatch(
        getTemplateMaster({
          archived: false,
          condensed: true
        })
      );
      dispatch(searchEmployees(selectedEmployee.hcaid));
      dispatch(getAllQuestions());
    } else {
      navigate(window.localStorage.getItem('orbitLastPath') ?? '/');
    }
    return () => {
      dispatch(clearEmployeeSearch());
      dispatch(clearSelectedEmployee());
    };
  }, []);

  return (
    <div
      id="Validation-Profile"
      style={{
        display: 'flex',
        flexDirection: 'row',
        marginTop: 40,
        flex: 1,
        backgroundColor: 'white',
        height: '100%'
      }}
    >
      <HistorySideNav
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        type="validation"
      />
      <HistorySideDrawer
        selectedTab={selectedTab}
        selectedPerson={selectedEmployee}
        roundHistory={employeeRoundHistory}
        tasks={[]}
        type="validation"
        setSelectedTab={setSelectedTab}
      />
      <Round type="validation" selectedEmployee={selectedEmployee} />
    </div>
  );
};

const mapReduxStateToProps = (state: RootState) => {
  return {
    employeeRoundHistory: state.ReportReducer.employeeRoundHistory,
    issues: state.TaskReducer.employeeIssues,
    feedback: state.TaskReducer.employeeFeedback,
    selectedEmployee: state.UserReducer.selectedEmployee
  };
};

export default connect(mapReduxStateToProps)(ValidationProfile);
