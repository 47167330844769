/**
 * External Imports
 */
import { FC, useState, useEffect, useRef } from 'react';
import { NeuIcon, NeuButton, NeuRadio, NeuRadioGroup } from '@neutron/react';
import { connect, useDispatch } from 'react-redux';
import {
  NeuInputChangeEventDetail,
  NeuRadioGroupChangeEventDetail
} from '@neutron/core';
/**
 * Internal Imports
 */
import { RootState } from '../../../redux/store';
import {
  getUserRoles,
  getFacilities,
  getDivisions,
  postUserAccess
} from '../../../redux/actions/User.action';
import MultiSelectDropdown from '../../MultiSelect/MultiSelectDropdown';
import Loading from '../../shared/Loading';
import { handleModal } from '../../../services/ModalPortal/utils';
/**
 * Global Type Definition Imports
 */
import { IFacility } from '../../../config/interfaces';
/**
 * Style Imports
 */
import {
  ProvisioningCard,
  CardHeader,
  ModalCardContent,
  InputHeading,
  RadioContainer,
  RadioLabel,
  ModalTextArea,
  ModalFooter,
  ModalTitle
} from './AddProvisioningModal.styles';

interface ProvisioningModalProps {
  userRoles: [];
  facilities: [];
  divisions: [];
  loadingUserRoles: boolean;
  loadingFacilities: boolean;
  loadingDivisions: boolean;
}
interface UserRoles {
  id: string;
  value: string;
}
const AddProvisioningModal: FC<ProvisioningModalProps> = ({
  userRoles = [],
  facilities = [],
  divisions = [],
  loadingUserRoles = false,
  loadingFacilities = false,
  loadingDivisions = false
}) => {
  /** State variables */
  const dispatch = useDispatch();
  const [showAccessLevel, setShowAccessLevel] = useState<boolean>(true);
  const [accessLevelList, setAccessLevelList] = useState([]);
  const [defaultSelection, setDefaultSelection] = useState([]);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [selectedAccessLevel, setSelectedAccessLevel] =
    useState<string>('facility');
  const [defaultUserRoles, setDefaultUserRoles] = useState([]);
  const [selectedUserRole, setSelectedUserRole] = useState<string[]>([]);
  const [selected34Ids, setSelected34Ids] = useState<string>('');
  const [selected34IdArray, setSelected34IdArray] = useState<string[]>([]);
  const [isInvalid34IDs, setIsInvalid34IDs] = useState<boolean>(false);
  const [showButton, setShowButton] = useState<boolean>(false);
  const modalContentRef = useRef<HTMLNeuCardElement>(null);

  /** CTA block */
  const handleSelected = (selected: []) => {
    const selectedIdArray =
      selected &&
      selected.length > 0 &&
      selected.map((item: IFacility) => item.id);
    if (selectedIdArray) setSelectedItems(selectedIdArray);
  };
  const handleSelectedUserRoles = (selected: []) => {
    const selectedRoles =
      selected &&
      selected.length > 0 &&
      selected.map((item: UserRoles) => item.id);
    if (selectedRoles) setSelectedUserRole(selectedRoles);
  };

  const onAccessLevelChange = (
    e: CustomEvent<NeuRadioGroupChangeEventDetail>
  ) => {
    // empty string when modal resets
    const selectedLevel = e.detail.value ?? '';
    setShowAccessLevel(true);
    setAccessLevelList(facilities);
    if (selectedLevel === 'division') {
      setAccessLevelList(divisions);
    } else if (selectedLevel === 'facility') {
      setAccessLevelList(facilities);
      // appInsights.trackEvent({
      //   name: 'Facility_Selected'
      // });
    } else {
      setShowAccessLevel(false);
      // appInsights.trackEvent({
      //   name: 'Facility_Selection_Failed'
      // });
    }
    // Reset selected items when facility/division change
    if (selectedItems && selectedItems.length > 0) {
      setDefaultSelection([]);
    }
    setSelectedAccessLevel(selectedLevel);
  };

  const handle34IdChange = (event: CustomEvent<NeuInputChangeEventDetail>) => {
    const target = event.target as HTMLInputElement;
    const selected34IDs = target.value;
    setSelected34Ids(selected34IDs);
    if (selected34IDs) {
      // Split the input string with space, comma or new line
      const formated34IDs = selected34IDs.trim().split(/,|\s+|\n+}/);
      const formatted34IDarray = formated34IDs.filter((item: string) => item);
      setSelected34IdArray(formatted34IDarray);
      const condition = /^([a-z]{3})?([0-9]{4})+$/i;
      const invalidIDs = formatted34IDarray.filter(
        (item: string) => !condition.test(item)
      );
      setIsInvalid34IDs(false);
      // appInsights.trackEvent({
      //   name: 'User_Configuration_Change'
      // });
      if (invalidIDs && invalidIDs.length > 0) {
        setIsInvalid34IDs(true);
        // appInsights.trackEvent({
        //   name: 'User_Configuration_Change_Failed'
        // });
      }
    }
  };

  const handleReset = () => {
    setDefaultUserRoles([]);
    setSelectedAccessLevel('facility');
    setDefaultSelection([]);
    setSelectedUserRole([]);
    setSelected34Ids('');
    setSelected34IdArray([]);
  };
  const handleSubmit = () => {
    if (showButton) {
      const postParams = {
        employeeId: '',
        access: {
          accessLevel: selectedAccessLevel,
          accessLevelIds:
            selectedAccessLevel === 'master' ? ['HCA'] : selectedItems
        },
        roles: selectedUserRole,
        primaryUnit: [
          {
            facilityId: '',
            unitId: ''
          }
        ]
      };
      selected34IdArray.forEach((item: string) => {
        postParams.employeeId = item;
        dispatch(postUserAccess(postParams));
      });
      setShowButton(false);
      handleReset();
      handleModal(modalContentRef);
    }
  };
  /** CTA block - End */

  const showSubmitButton = () => {
    if (
      !isInvalid34IDs &&
      selectedUserRole.length > 0 &&
      selectedAccessLevel &&
      selected34Ids
    ) {
      if (selectedAccessLevel === 'master') {
        return true;
      }
      if (selectedAccessLevel !== 'master' && selectedItems.length > 0) {
        return true;
      }
      return false;
    }
    return false;
  };

  /** Data fetching */
  useEffect(() => {
    dispatch(getUserRoles());
    dispatch(getFacilities());
    dispatch(getDivisions());
  }, []);

  useEffect(() => {
    if (showSubmitButton()) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  }, [
    isInvalid34IDs,
    selectedUserRole,
    selectedAccessLevel,
    selectedItems,
    selected34Ids
  ]);

  return (
    <ProvisioningCard
      id="Modal-Provision"
      type="submit"
      className="show-modal"
      maxWidth="413px"
      minHeight="100vh"
      ref={modalContentRef}
    >
      <CardHeader>
        <ModalTitle>Provisioning</ModalTitle>
        <NeuButton
          id="Modal-Provision-Close-Button"
          fill="flat"
          onClick={() => handleModal(modalContentRef)}
        >
          <NeuIcon large color="primary">
            close
          </NeuIcon>
          Close
        </NeuButton>
      </CardHeader>
      {loadingUserRoles || loadingFacilities || loadingDivisions ? (
        <ModalCardContent>
          <Loading />
        </ModalCardContent>
      ) : (
        <>
          <ModalCardContent>
            <InputHeading>User Role</InputHeading>
            {userRoles && (
              <MultiSelectDropdown
                options={userRoles}
                defaultselection={defaultUserRoles}
                selected={handleSelectedUserRoles}
                id="Modal-Provision-Select-User-Role"
                showArrow={false}
                searchable={false}
                chipsInside={false}
              />
            )}

            <InputHeading>Access Level</InputHeading>
            <NeuRadioGroup
              id="Modal-Provision-Access-Level"
              onNeuChange={onAccessLevelChange}
              name="accesLevel"
            >
              <RadioContainer>
                <NeuRadio value="facility">Facility</NeuRadio>
                <RadioLabel>Facility</RadioLabel>
                <NeuRadio value="division">Division</NeuRadio>
                <RadioLabel>Division</RadioLabel>
                <NeuRadio value="master">Corporate</NeuRadio>
                <RadioLabel>Corporate</RadioLabel>
              </RadioContainer>
            </NeuRadioGroup>

            {showAccessLevel && accessLevelList && (
              <>
                <InputHeading>
                  {selectedAccessLevel.charAt(0).toUpperCase() +
                    selectedAccessLevel.slice(1)}
                </InputHeading>
                <MultiSelectDropdown
                  options={accessLevelList}
                  defaultselection={defaultSelection}
                  selected={handleSelected}
                  id="Facility-Division-Select"
                  showArrow={false}
                  searchable={false}
                  chipsInside={false}
                />
              </>
            )}

            <InputHeading>Users to Provision</InputHeading>
            <ModalTextArea
              placeholder="Enter 3/4 IDs separated by comma, space or new line"
              error={isInvalid34IDs}
              required
              value={selected34Ids}
              onNeuChange={handle34IdChange}
            />
          </ModalCardContent>
          <ModalFooter small>
            <NeuButton
              color="danger"
              onClick={(e: any) => {
                onAccessLevelChange(e);
                handleReset();
              }}
            >
              Reset
            </NeuButton>
            <NeuButton
              color="primary"
              style={{ float: 'right' }}
              onClick={handleSubmit}
              disabled={!showButton}
            >
              Submit
            </NeuButton>
          </ModalFooter>
        </>
      )}
    </ProvisioningCard>
  );
};
const mapReduxStateToProps = (state: RootState) => {
  return {
    userRoles: state.UserReducer?.userRoles,
    facilities: state.UserReducer?.facilities,
    divisions: state.UserReducer?.divisions,
    loadingUserRoles: state.UserReducer?.loadingUserRoles,
    loadingFacilities: state.UserReducer?.loadingFacilities,
    loadingDivisions: state.UserReducer?.loadingDivisions,
    modalState: state.ModalReducer.modal
  };
};

export default connect(mapReduxStateToProps)(AddProvisioningModal);
