import { FC, useEffect } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';

import ReusableTabs from '../../components/reusableTabs';
import FacilityAndUnitSettings from './FacilityAndUnitSettings';
import EmployeeRoundingSettings from './EmployeeRoundingSettings';

import { RootState } from '../../redux/store';
import { getEmployeeAccountDetails } from '../../redux/actions/Employee.action';

import { AuthUserProps } from '../../config/interfaces';
import { useUserRolesRef } from '../../services/UserRoles/userRoles';

interface AccountProps {
  authorizedUser: AuthUserProps;
}

const Account: FC<AccountProps> = ({ authorizedUser }) => {
  const dispatch = useDispatch();
  const {
    isEmployeeRounder,
    isCSCRounder,
    isCNEdRounder,
    isValidationRounder,
    isNonSupportRounder,
    isSupportRounder,
    isTemplateConfigurator,
    isUserAdmin
  } = useUserRolesRef();

  useEffect(() => {
    if (authorizedUser.hcaid) {
      dispatch(getEmployeeAccountDetails(authorizedUser.hcaid));
    }
  }, [authorizedUser]);

  const reusableTabItems = {
    base: '/account',
    links: [
      // TODO removed until notifications are managed in orbit
      // { label: 'Notifications', path: '/notifications' },
      ...(isNonSupportRounder || isTemplateConfigurator || isUserAdmin
        ? [
            {
              label: 'Facility and Unit Settings',
              path: '/facility-and-unit-settings'
            }
          ]
        : []),
      ...(isEmployeeRounder || isSupportRounder || isValidationRounder
        ? [
            {
              label: 'Employee Rounding Settings',
              path: isEmployeeRounder
                ? '/employee-rounding-settings'
                : isCSCRounder
                ? '/employee-rounding-settings/csc-list'
                : isCNEdRounder
                ? '/employee-rounding-settings/cned-list'
                : '/employee-rounding-settings/validation-list'
            }
          ]
        : [])
    ]
  };

  return (
    <div id="Account">
      <div>
        <ReusableTabs tabItems={reusableTabItems} />
        <Routes>
          {/* TODO add back when notifications are managed in Orbit */}
          {/* <Route path="notifications" element="Notifications" /> */}
          {(isNonSupportRounder || isTemplateConfigurator || isUserAdmin) && (
            <Route
              path="facility-and-unit-settings"
              element={<FacilityAndUnitSettings />}
            />
          )}
          {(isEmployeeRounder || isSupportRounder || isValidationRounder) && (
            <Route path="employee-rounding-settings" element={<Outlet />}>
              <Route index element={<EmployeeRoundingSettings />} />
              <Route
                path=":empRoundSettingsTable"
                element={<EmployeeRoundingSettings />}
              />
            </Route>
          )}
          <Route
            path="*"
            element={
              <Navigate
                to={localStorage.getItem('orbitLastPath') || '/'}
                replace
              />
            }
          />
        </Routes>
      </div>
    </div>
  );
};

const mapReduxStateToProps = (state: RootState) => ({
  authorizedUser: state.AuthorizedUser.authorizedUser
});

export default connect(mapReduxStateToProps)(Account);
