import styled from 'styled-components';
import { NeuIcon } from '@neutron/react';

// TODO: These need similar styles to the Past Round Cards
export const CardContainer = styled.div``;

export const CardHeader = styled.p`
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #124e7f;
  margin-right: 3px;
  width: 100%;
  overflow-wrap: anywhere;
`;

export const CardHeaderContainer = styled.div`
  display: flex;
`;

export const CardContentContainer = styled.div`
  display: flex;
`;

export const StatusContainer = styled.div`
  margin-top: 10px;
  display: flex;
  gap: 10px;
`;
export const CardContent = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  width: 237px;
`;

export const CardHeaderRight = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: right;
  margin-left: auto;
  margin-right: 20px;
`;

export const CardSubHeader = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
`;

export const ChevronRightIcon = styled(NeuIcon)`
  margin-left: auto;
  color: #124e7f;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  margin-top: 10%;
`;

export const StatusIconCompleted = styled(NeuIcon)`
  margin-left: auto;
  color: #548a2c;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
`;

export const StatusIconOpened = styled(NeuIcon)`
  margin-left: auto;
  color: #3282ba;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
`;

export const HorizontalDivider = styled.hr`
  margin-top: 15px;
  border-top: 1px solid rgb(224, 224, 224);
  margin-left: -15px;
  margin-right: -15px;
`;
