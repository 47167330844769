import { FC, useCallback, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';

import SupportListSettingsTable from './supportListSettingsTable/SupportListSettingsTable';

import {
  getCNEdEmployeesConfig,
  updateCNEdHiddenStatus
} from '../../../redux/actions/Employee.action';

import { useFeatures } from '../../../services/Features/features';
import { useUserRolesRef } from '../../../services/UserRoles';

import { RootState } from '../../../redux/store';

import { AuthUserProps, CNEdEmployee } from '../../../config/interfaces';

interface CNEdListSettingsProps {
  authorizedUser?: AuthUserProps;
  cnedEmployees: CNEdEmployee[];
  facilityId: string;
  userId: string;
}

const CNEdListSettingsTable: FC<CNEdListSettingsProps> = ({
  authorizedUser,
  cnedEmployees,
  facilityId,
  userId
}) => {
  const dispatch = useDispatch();

  const {
    isCNEdRounder,
    isEnterpriseAdmin,
    isNonSupportRounder,
    isTemplateConfigurator,
    isUserAdmin
  } = useUserRolesRef();

  const { isCNEdEnabled } = useFeatures();

  const hideEmployee = useCallback(
    (employee: CNEdEmployee) => {
      dispatch(
        updateCNEdHiddenStatus({
          userId,
          facilityId,
          employeeId: employee.hcaid,
          visible: !employee.visible
        })
      );
    },
    [facilityId, userId]
  );

  useEffect(() => {
    if (isCNEdEnabled) dispatch(getCNEdEmployeesConfig(authorizedUser?.hcaid));
  }, [authorizedUser, isCNEdEnabled]);

  return (
    <SupportListSettingsTable
      employees={cnedEmployees}
      isFacilitySelectorEnabled={
        isEnterpriseAdmin ||
        (isCNEdRounder &&
          !(isNonSupportRounder || isTemplateConfigurator || isUserAdmin))
      }
      isTableEnabled={isCNEdEnabled}
      type="cned"
      hideEmployee={hideEmployee}
    />
  );
};

const mapReduxStateToProps = (state: RootState) => {
  return {
    authorizedUser: state.AuthorizedUser.authorizedUser,
    cnedEmployees: state.EmployeeReducer.cnedEmployees,
    facilityId: state.AuthorizedUser.authorizedUser.facilityId,
    userId: state.AuthorizedUser.authorizedUser.hcaid
  };
};

export default connect(mapReduxStateToProps)(CNEdListSettingsTable);
