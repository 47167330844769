import { FC, useCallback, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';

import SupportListSettingsTable from './supportListSettingsTable/SupportListSettingsTable';

import {
  getValidationEmployeesConfig,
  updateValidationHiddenStatus
} from '../../../redux/actions/Employee.action';

import { useFeatures } from '../../../services/Features/features';
import { useUserRolesRef } from '../../../services/UserRoles';

import { RootState } from '../../../redux/store';

import { AuthUserProps, ValidationEmployee } from '../../../config/interfaces';

interface ValidationListSettingsProps {
  authorizedUser?: AuthUserProps;
  validationEmployees: ValidationEmployee[];
  facilityId: string;
  userId: string;
}

const ValidationListSettingsTable: FC<ValidationListSettingsProps> = ({
  authorizedUser,
  validationEmployees,
  facilityId,
  userId
}) => {
  const dispatch = useDispatch();

  const {
    isEnterpriseAdmin,
    isNonSupportRounder,
    isTemplateConfigurator,
    isUserAdmin,
    isValidationRounder
  } = useUserRolesRef();

  const { isValidationEnabled } = useFeatures();

  const hideEmployee = useCallback(
    (employee: ValidationEmployee) => {
      dispatch(
        updateValidationHiddenStatus({
          userId,
          facilityId,
          employeeId: employee.hcaid,
          visible: !employee.visible
        })
      );
    },
    [facilityId, userId]
  );

  useEffect(() => {
    if (isValidationEnabled)
      dispatch(getValidationEmployeesConfig(authorizedUser?.hcaid));
  }, [authorizedUser, isValidationEnabled]);

  return (
    <SupportListSettingsTable
      employees={validationEmployees}
      isFacilitySelectorEnabled={
        isEnterpriseAdmin ||
        (isValidationRounder &&
          !(isNonSupportRounder || isTemplateConfigurator || isUserAdmin))
      }
      isTableEnabled={isValidationEnabled}
      type="validation"
      hideEmployee={hideEmployee}
    />
  );
};

const mapReduxStateToProps = (state: RootState) => {
  return {
    authorizedUser: state.AuthorizedUser.authorizedUser,
    validationEmployees: state.EmployeeReducer.validationEmployees,
    facilityId: state.AuthorizedUser.authorizedUser.facilityId,
    userId: state.AuthorizedUser.authorizedUser.hcaid
  };
};

export default connect(mapReduxStateToProps)(ValidationListSettingsTable);
