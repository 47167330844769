import { FC, useEffect, useState, useMemo } from 'react';
import {
  NeuTable,
  NeuTableRow,
  NeuTableHeading,
  NeuTableBody,
  NeuLabel,
  NeuPaginator
} from '@neutron/react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { useAnalyticsApi } from '@shared-web-analytics/react/dist';

import NoDataSplashView from '../../noDataSplashView/NoDataSplashView';

import { setSelectedPatient } from '../../../redux/actions/User.action';
import { clearPatientRoundHistory } from '../../../redux/actions/Report.action';
import { clearTasksByAccountNumber } from '../../../redux/actions/Task.action';
import { logRoundProfile } from '../../../utils/analyticsHelpers';
import { calculateStayTime, reverseName } from '../../../utils/helpers';
import { Patient } from '../../../config/interfaces';
import { StyledLabel } from '../../shared/table/Table.styles';
import {
  ToDoLabel,
  ToDoIcon,
  ToDoRow,
  CompleteRow,
  CompleteIcon,
  // UnableRow,
  // UnableStayLabel,
  PaginationContainer,
  UnableStayLabel
} from './CensusTable.styles';

interface CensusTableProps {
  patientsToRound: Patient[];
  searchTerm: string;
  selectedTab: string;
}

const CensusTable: FC<CensusTableProps> = ({
  patientsToRound,
  searchTerm,
  selectedTab
}) => {
  const dispatch = useDispatch();
  const [pageSize] = useState(50);
  const [pageNumber, setPageNumber] = useState(1);
  const [ptr, setPtr] = useState<Patient[]>([]);
  const [filteredPtr, setFilteredPtr] = useState<Patient[]>([]);
  const [sortDir, setSortDir] = useState<'desc' | 'asc'>('desc');
  const [sortKey, setSortKey] = useState<string>('lastRoundDate');
  const [active, setActive] = useState<string>('Last Round');
  const [isTableEmpty, setIsTableEmpty] = useState(false);
  const [isTableEmptySearch, setIsTableEmptySearch] = useState(false);
  const [isTableEmptyCompleted, setIsTableEmptyCompleted] = useState(false);

  const { logTrackingEvent } = useAnalyticsApi();

  const navigate = useNavigate();

  useEffect(() => {
    if (filteredPtr.length === 0 && !searchTerm) {
      if (selectedTab === 'Completed') {
        setIsTableEmptyCompleted(true);
        setIsTableEmpty(false);
        setIsTableEmptySearch(false);
      } else {
        setIsTableEmpty(true);
        setIsTableEmptyCompleted(false);
        setIsTableEmptySearch(false);
      }
    } else {
      setIsTableEmpty(false);
      setIsTableEmptySearch(false);
      setIsTableEmptyCompleted(false);
    }

    if (filteredPtr.length === 0 && searchTerm) {
      setIsTableEmptySearch(true);
      setIsTableEmpty(false);
      setIsTableEmptyCompleted(false);
    } else {
      setIsTableEmptySearch(false);
    }
  }, [filteredPtr, searchTerm]);

  useEffect(() => {
    if (patientsToRound) {
      setPtr(patientsToRound);
      setFilteredPtr(patientsToRound);
    }
  }, [patientsToRound]);

  useEffect(() => {
    const q = new RegExp(searchTerm.trim().replace(/\\/g, ''), 'i');
    setFilteredPtr(
      ptr.filter(
        patient =>
          patient.firstName.match(q) ||
          patient.lastName.match(q) ||
          `${patient.firstName} ${patient.lastName}`.match(q) ||
          `${patient.lastName} ${patient.firstName}`.match(q) ||
          `${patient.lastName}, ${patient.firstName}`.match(q) ||
          patient.mrn.match(q)
      )
    );
  }, [ptr, searchTerm]);

  const pageNumberChanged = (pageNum: number) => {
    if (pageNum) setPageNumber(pageNum);
  };

  const sortedPtr = useMemo(() => {
    const stateCopy = [...filteredPtr];
    const sorted = stateCopy.sort((a: Patient, b: Patient) => {
      if (sortKey === 'lengthOfStay') {
        if (a[sortKey] === b[sortKey]) {
          return 0;
        }
        if (!a[sortKey]) {
          return sortDir === 'asc' ? 1 : -1;
        }
        if (!b[sortKey]) {
          return sortDir === 'asc' ? -1 : 1;
        }
        if (sortDir === 'asc') {
          return b[sortKey] - a[sortKey];
        }
        return a[sortKey] - b[sortKey];
      }
      if (a[sortKey] === b[sortKey]) {
        return 0;
      }
      if (!a[sortKey]) {
        return sortDir === 'asc' ? 1 : -1;
      }
      if (!b[sortKey]) {
        return sortDir === 'asc' ? -1 : 1;
      }
      if (sortDir === 'asc') {
        return a[sortKey]! > b[sortKey]! ? 1 : -1;
      }
      return a[sortKey]! > b[sortKey]! ? -1 : 1;
    });
    return sorted;
  }, [filteredPtr, sortDir, sortKey]);

  const patientsData = useMemo(() => {
    return sortedPtr.slice(
      pageNumber * pageSize - pageSize,
      pageNumber * pageSize
    );
  }, [pageNumber, pageSize, sortedPtr]);

  const sortByHeading = (key: string, column: string) => {
    if (key === sortKey && sortDir === 'asc') {
      setSortDir('desc');
    } else {
      setSortDir('asc');
    }
    setActive(column);
    setSortKey(key);
  };

  const openPatient = (selectedPatient: Patient) => {
    dispatch(clearPatientRoundHistory());
    dispatch(clearTasksByAccountNumber());
    dispatch(setSelectedPatient(selectedPatient));
    logTrackingEvent(logRoundProfile());
    if (selectedPatient.accountNum.substring(0, 2) === 'UL') {
      navigate('/patients/profile', {
        state: { isUnlistedPatient: true }
      });
    } else {
      navigate('/patients/profile', {
        state: { isUnlistedPatient: false }
      });
    }
  };

  return (
    <NeuTable>
      <NeuTableRow
        style={{ backgroundColor: 'white', height: '56px' }}
        columns="{'Patient': '20%', 'Unit': '20%', 'Room': '10%', 'Stay': '10%', 'Last Rounded By': '20%', 'Last Round': '20%'}"
        header
      >
        <NeuTableHeading
          id="Census-Table-Patient-Column"
          slot="Patient"
          icon={
            !(active === 'Patient') ? 'asc' : sortDir === 'asc' ? 'desc' : 'asc'
          }
          onClick={() => sortByHeading('lastName', 'Patient')}
          active={active === 'Patient'}
        >
          Patient
        </NeuTableHeading>
        <NeuTableHeading
          id="Census-Table-Unit-Column"
          slot="Unit"
          icon={
            !(active === 'Unit') ? 'asc' : sortDir === 'asc' ? 'desc' : 'asc'
          }
          onClick={() => sortByHeading('unitId', 'Unit')}
          active={active === 'Unit'}
        >
          Unit
        </NeuTableHeading>
        <NeuTableHeading
          id="Census-Table-Room-Column"
          slot="Room"
          icon={
            !(active === 'Room') ? 'asc' : sortDir === 'asc' ? 'desc' : 'asc'
          }
          onClick={() => sortByHeading('room', 'Room')}
          active={active === 'Room'}
        >
          Room
        </NeuTableHeading>
        <NeuTableHeading
          id="Census-Table-Stay-Column"
          slot="Stay"
          icon={
            !(active === 'Stay') ? 'asc' : sortDir === 'asc' ? 'desc' : 'asc'
          }
          onClick={() => sortByHeading('lengthOfStay', 'Stay')}
          active={active === 'Stay'}
        >
          Stay
        </NeuTableHeading>
        <NeuTableHeading
          id="Census-Table-Last-Rounded-Column"
          slot="Last Rounded By"
          icon={
            !(active === 'Last Rounded By')
              ? 'asc'
              : sortDir === 'asc'
              ? 'desc'
              : 'asc'
          }
          onClick={() => sortByHeading('lastRoundedBy', 'Last Rounded By')}
          active={active === 'Last Rounded By'}
        >
          Last Rounded By
        </NeuTableHeading>
        <NeuTableHeading
          id="Census-Table-Last-Round-Column"
          slot="Last Round"
          icon={
            !(active === 'Last Round')
              ? 'asc'
              : sortDir === 'asc'
              ? 'desc'
              : 'asc'
          }
          onClick={() => sortByHeading('lastRoundDate', 'Last Round')}
          active={active === 'Last Round'}
        >
          Last Round
        </NeuTableHeading>
      </NeuTableRow>
      {isTableEmpty ? (
        <NoDataSplashView type="patientCensus" />
      ) : isTableEmptySearch ? (
        <NoDataSplashView type="patientCensusSearch" />
      ) : isTableEmptyCompleted ? (
        <NoDataSplashView type="patientCensusCompleted" />
      ) : (
        <>
          <NeuTableBody>
            {/* eslint-disable-next-line @typescript-eslint/default-param-last */}
            {patientsData.map((patient: Patient, i: number) => {
              return patient.roundStatus === 'To Do' ? (
                <ToDoRow
                  id={`ToDo-Row-${patient.mrn}`}
                  className="ToDo-Row-Census"
                  columns="{'Patient': '20%', 'Unit': '20%', 'Room': '10%', 'Stay': '10%', 'Last Rounded By': '20%', 'Last Round': '20%'}"
                  size="large"
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                  onClick={() => openPatient(patient)}
                >
                  <ToDoLabel color="primary-70" slot="Patient">
                    <ToDoIcon color="secondary-50">circle</ToDoIcon>
                    {reverseName(patient.firstName, patient.lastName)}
                  </ToDoLabel>
                  <NeuLabel slot="Unit">{patient.unitId}</NeuLabel>
                  <NeuLabel slot="Room">{patient.room}</NeuLabel>
                  {patient.dischargetime || patient.transferred ? (
                    <UnableStayLabel slot="Stay">
                      {patient.transferred ? 'Transferred' : 'Discharged'}
                    </UnableStayLabel>
                  ) : (
                    <NeuLabel slot="Stay">
                      {patient.lengthOfStay !== -1
                        ? calculateStayTime(patient.lengthOfStay)
                        : ''}
                    </NeuLabel>
                  )}
                  <NeuLabel slot="Last Rounded By">
                    {patient.lastRoundedBy}
                  </NeuLabel>
                  <NeuLabel slot="Last Round">
                    {patient.formattedLastRoundDate}
                  </NeuLabel>
                </ToDoRow>
              ) : (
                <CompleteRow
                  id={`Complete-Row-${patient.mrn}`}
                  className="Complete-Row-Census"
                  columns="{'Patient': '20%', 'Unit': '20%', 'Room': '10%', 'Stay': '10%', 'Last Rounded By': '20%', 'Last Round': '20%'}"
                  size="large"
                  onClick={() => openPatient(patient)}
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                >
                  <NeuLabel
                    className="d-flex align-items-center"
                    color="gray-90"
                    slot="Patient"
                  >
                    <CompleteIcon className="mr-1 mb-1">check</CompleteIcon>
                    {reverseName(patient.firstName, patient.lastName)}
                  </NeuLabel>
                  <NeuLabel slot="Unit">{patient.unitId}</NeuLabel>
                  <NeuLabel slot="Room">{patient.room}</NeuLabel>
                  {patient.dischargetime ? (
                    <UnableStayLabel slot="Stay">
                      {patient.transferred ? 'Transferred' : 'Discharged'}
                    </UnableStayLabel>
                  ) : (
                    <NeuLabel slot="Stay">
                      {patient.lengthOfStay !== -1
                        ? calculateStayTime(patient.lengthOfStay)
                        : ''}
                    </NeuLabel>
                  )}
                  <StyledLabel slot="Last Rounded By">
                    {patient.lastRoundedBy}
                  </StyledLabel>
                  <NeuLabel slot="Last Round">
                    {patient.formattedLastRoundDate}
                  </NeuLabel>
                </CompleteRow>
              );
              // *******************************************
              // ********* Removing till Phase II **********
              // *******************************************
              //     : (
              //   <UnableRow
              //     columns="{'Patient': '20%', 'Unit': '20%', 'Room': '10%', 'Stay': '10%', 'Last Rounded By': '20%', 'Last Round': '20%'}"
              //     size="large"
              //     onClick={click => openPatient(patient)}
              //     key={patient.mrn}
              //   >
              //     <NeuLabel color="gray-90" slot="Patient">
              //       {patient.firstName} {patient.lastName}
              //     </NeuLabel>
              //     <NeuLabel slot="Unit">{patient.unitId}</NeuLabel>
              //     <NeuLabel slot="Room">{patient.room}</NeuLabel>
              //     <UnableStayLabel slot="Stay">
              //       {patient.transferred ? 'Transferred' : 'Discharged'}
              //     </UnableStayLabel>
              //     <NeuLabel slot="Last Rounded By">
              //       {patient.lastRoundedBy}
              //     </NeuLabel>
              //     <NeuLabel slot="Last Round">
              //       {patient.formattedLastRoundDate}
              //     </NeuLabel>
              //   </UnableRow>
              // );
            })}
          </NeuTableBody>
          {!(isTableEmpty || isTableEmptySearch || isTableEmptyCompleted) && (
            <PaginationContainer>
              <NeuPaginator
                total={filteredPtr.length}
                rowsPerPage={pageSize}
                onNeuChange={e => pageNumberChanged(e.detail.pageNumber)}
              />
            </PaginationContainer>
          )}
        </>
      )}
    </NeuTable>
  );
};

export default CensusTable;
