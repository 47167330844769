/**
 * External Imports
 */
import styled from 'styled-components';
import { NeuImage, NeuIcon, NeuLabel, NeuTableRow } from '@neutron/react';

export const IconDiv = styled.div`
  padding-top: 10px;
`;

export const AvatarDiv = styled.div`
  padding-top: 5px;
`;

export const EmployeeInfoDiv = styled.div`
  display: flex;
  align-items: center;
`;

export const EmployeeAvatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

export const CompetencyImg = styled(NeuImage)`
  // padding-left: 1.5rem;
`;

export const LastValidationLabel = styled(NeuLabel)`
  flex: 0 0 100px;
`;

export const OverdueImg = styled(NeuImage)`
  margin-right: 2px;
`;

export const ToDoIcon = styled(NeuIcon)`
  font-size: 12px;
`;

export const ToDoRow = styled(NeuTableRow)`
  background-color: white;
  min-height: 5rem;
  align-items: center;
`;

export const ToDoLabel = styled(NeuLabel)`
  font-size: 14px;
  line-height: 22px;
  font-weight: bold;
`;

export const CompleteIcon = styled(NeuIcon)`
  color: #00558c;
  font-size: 24px;
  width: 24px;
`;

export const CompleteRow = styled(NeuTableRow)`
  background-color: #f6f6f6;
  min-height: 5rem;
  align-items: center;
`;
