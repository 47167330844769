import { FC, useEffect, useMemo, useState } from 'react';

import {
  NeuTable,
  NeuTableRow,
  NeuTableHeading,
  NeuTableBody,
  NeuLabel,
  NeuPaginator
} from '@neutron/react';
import { PaginationContainer } from './EncountersHistoryTable.styles';

import { toTitleCase, date } from '../../../utils/helpers';
import { Encounter, RoundHistory } from '../../../config/interfaces';

interface EncountersHistoryTableProps {
  encounterData?: Encounter | null;
  roundHistory: RoundHistory[] | null;
  gotoRoundPage: (round: RoundHistory) => void;
}
const EncountersHistoryTable: FC<EncountersHistoryTableProps> = ({
  encounterData,
  roundHistory,
  gotoRoundPage
}) => {
  const [filteredPtr, setFilteredPtr] = useState<RoundHistory[]>([]);
  const [pageSize] = useState(50);
  const [pageNumber, setPageNumber] = useState(1);
  const [sortDir, setSortDir] = useState<'desc' | 'asc'>('desc');
  const [sortKey, setSortKey] = useState<string>('roundDate');
  const [active, setActive] = useState<string>('Timestamp');
  const parseADT = (message: string | undefined) => {
    const lastTwo =
      (message && message.substring(message.length - 2, message.length)) || '';

    switch (lastTwo) {
      case '01':
        return 'Admitted/Visited Notification';
      case '02':
        return 'Transferred a Patient';
      case '03':
        return 'Discharged/Ended Visit';
      case '04':
        return 'Registered a Patient';
      case '05':
        return '';
      case '06':
        return 'Changed an Outpatient to an Inpatient';
      case '07':
        return '';
      case '08':
        return 'Updated Patient Information';
      case '09':
        return 'Patient Departed';
      case '20':
        return 'Updated Bed Status';
      default:
        return '';
    }
  };

  const checkInCensus = (history: Encounter) => {
    let admissionCheck: boolean;
    const today = new Date();
    const formattedAdmitTime = new Date(
      Number(encounterData?.admittime?.slice(0, 4)),
      parseInt(String(encounterData?.admittime?.slice(4, 6)), 10) - 1,
      Number(encounterData?.admittime?.slice(6, 8)),
      Number(encounterData?.admittime?.slice(8, 10)),
      Number(encounterData?.admittime?.slice(10, 12))
    );

    const formattedDischargeTime = new Date(
      Number(history.dischargetime?.slice(0, 4)),
      parseInt(history.dischargetime?.slice(4, 6), 10) - 1,
      Number(history.dischargetime?.slice(6, 8)),
      Number(history.dischargetime?.slice(8, 10)),
      Number(history.dischargetime?.slice(10, 12))
    );

    const admitTimeNotInFuture = formattedAdmitTime <= today;
    const dischargedTimeNotInFuture = formattedDischargeTime <= today;
    const dischargeNotNull = history.dischargetime && history.dischargetime;

    if (
      history.admissionlevel === 'DIS' ||
      history.admissionlevel === 'ADM' ||
      history.admissionlevel === 'REG'
    )
      admissionCheck = true;
    else admissionCheck = false;

    const hasUnit = history && history.pointofcare;

    if (
      hasUnit &&
      admissionCheck &&
      admitTimeNotInFuture &&
      dischargedTimeNotInFuture &&
      dischargeNotNull
    )
      return 'Y';
    return 'N';
  };

  useEffect(() => {
    if (encounterData?.history.length || roundHistory?.length) {
      // deep copying history data, this doesnt exist often, typing any
      let cleanData: any = encounterData?.history.map((a: any) => {
        return { ...a };
      });

      cleanData?.forEach((data: any) => {
        const history = data;
        let reverseFormattedTime;
        const reverseFormattedDate = `${
          history.msgtime?.split(' ')[0].split('/')[2]
        }${history.msgtime?.split(' ')[0].split('/')[0]}${history.msgtime
          ?.split(' ')[0]
          .split('/')[1]
          .padStart(2, '0')}`;

        if (history.msgtime?.split(' ')[2] === 'AM') {
          reverseFormattedTime = `${
            history.msgtime?.split(' ')[1].split(':')[0]
          }${history.msgtime?.split(' ')[1].split(':')[1]}${
            history.msgtime?.split(' ')[1].split(':')[2]
          }`;
        }
        if (history.msgtime?.split(' ')[2] === 'PM') {
          reverseFormattedTime = `${
            parseInt(history.msgtime?.split(' ')[1].split(':')[0], 10) + 12
          }${history.msgtime?.split(' ')[1].split(':')[1]}${
            history.msgtime?.split(' ')[1].split(':')[2]
          }`;
        }

        const reverseFormattedDatetime = `${reverseFormattedDate}${reverseFormattedTime}`;

        history._patientStatus = encounterData?.patientstatus;
        history._adtMessage = parseADT(encounterData?.msgtype);
        [history._date, ...history._time] = history.msgtime.split(' ');
        history._census = checkInCensus(history);
        history.roundDate = reverseFormattedDatetime; // for sorting purpose
      });

      roundHistory?.forEach((data: RoundHistory) => {
        let roundDateCorrected = data.roundDate;

        if (roundDateCorrected.length < 14) {
          roundDateCorrected = roundDateCorrected.padEnd(14, '0');
        }

        const tempObj = data;
        const dateTime = date
          .dateTimeCombination(roundDateCorrected)
          .replace(', ', ' ');
        const rounder = `${data.userFirstName} ${data.userLastName} Rounded`;
        const roundedOn = `on ${toTitleCase(
          encounterData?.firstname
        )} ${toTitleCase(encounterData?.lastname)}`;
        [tempObj._date, ...tempObj._time] = dateTime.split(' ');
        tempObj.msgtype = rounder;
        tempObj._adtMessage = roundedOn;
        tempObj.pointofcare = data.unitId;
        tempObj._patientStatus = '-';
      });

      cleanData = cleanData.concat(roundHistory);

      setFilteredPtr(cleanData);
    }
  }, [encounterData, roundHistory, pageNumber, pageSize]);

  const sortedPtr = useMemo(() => {
    const stateCopy = [...filteredPtr];
    const sorted = stateCopy.sort((a: RoundHistory, b: RoundHistory) => {
      if (a[sortKey] === b[sortKey]) {
        return 0;
      }
      if (!a[sortKey]) {
        return sortDir === 'asc' ? 1 : -1;
      }
      if (!b[sortKey]) {
        return sortDir === 'asc' ? -1 : 1;
      }
      if (sortDir === 'asc') {
        return a[sortKey]! > b[sortKey]! ? 1 : -1;
      }
      return a[sortKey]! > b[sortKey]! ? -1 : 1;
    });
    return sorted;
  }, [filteredPtr, sortDir, sortKey]);

  const listToDisplay = useMemo(() => {
    return sortedPtr.slice(
      pageNumber * pageSize - pageSize,
      pageNumber * pageSize
    );
  }, [pageNumber, pageSize, sortedPtr]);

  const sortByHeading = (key: string, column: string) => {
    if (key === sortKey && sortDir === 'asc') {
      setSortDir('desc');
    } else {
      setSortDir('asc');
    }
    setActive(column);
    setSortKey(key);
  };

  const pageNumberChanged = (pageNum: number) => {
    if (pageNum) setPageNumber(pageNum);
  };

  return (
    <NeuTable style={{ height: 'calc(100vh - 280px)' }}>
      <NeuTableRow
        style={{ backgroundColor: 'white', height: '56px' }}
        columns="{'Timestamp': '20%', 'ADT': '25%', 'Patient Status': '15%', 'Unit': '10%', 'Room': '10%', 'Bed': '10%','Census': '10%'}"
        header
      >
        <NeuTableHeading
          slot="Timestamp"
          icon={
            !(active === 'Timestamp')
              ? 'asc'
              : sortDir === 'asc'
              ? 'desc'
              : 'asc'
          }
          onClick={() => sortByHeading('roundDate', 'Timestamp')}
          active={active === 'Timestamp'}
        >
          Timestamp
        </NeuTableHeading>
        <NeuTableHeading
          slot="ADT"
          icon="none"
          // icon={!(active === 'ADT') || sortDir === 'none' ? 'asc' : sortDir}
          // onClick={() => sortByHeading('msgtype', 'ADT')}
          // active={active === 'ADT' && sortDir !== 'none'}
        >
          ADT
        </NeuTableHeading>
        <NeuTableHeading
          slot="Patient Status"
          icon={
            !(active === 'Patient Status')
              ? 'asc'
              : sortDir === 'asc'
              ? 'desc'
              : 'asc'
          }
          onClick={() => sortByHeading('_patientStatus', 'Patient Status')}
          active={active === 'Patient Status'}
        >
          Patient Status
        </NeuTableHeading>
        <NeuTableHeading
          slot="Unit"
          icon={
            !(active === 'Unit') ? 'asc' : sortDir === 'asc' ? 'desc' : 'asc'
          }
          onClick={() => sortByHeading('pointofcare', 'Unit')}
          active={active === 'Unit'}
        >
          Unit
        </NeuTableHeading>
        <NeuTableHeading
          slot="Room"
          icon={
            !(active === 'Room') ? 'asc' : sortDir === 'asc' ? 'desc' : 'asc'
          }
          onClick={() => sortByHeading('room', 'Room')}
          active={active === 'Room'}
        >
          Room
        </NeuTableHeading>
        <NeuTableHeading
          slot="Bed"
          icon={
            !(active === 'Bed') ? 'asc' : sortDir === 'asc' ? 'desc' : 'asc'
          }
          onClick={() => sortByHeading('bed', 'Bed')}
          active={active === 'Bed'}
        >
          Bed
        </NeuTableHeading>
        <NeuTableHeading
          slot="Census"
          icon={
            !(active === 'Census') ? 'asc' : sortDir === 'asc' ? 'desc' : 'asc'
          }
          onClick={() => sortByHeading('_census', 'Census')}
          active={active === 'Census'}
        >
          Census
        </NeuTableHeading>
      </NeuTableRow>
      <NeuTableBody>
        {listToDisplay?.map((history: RoundHistory) => {
          return (
            <NeuTableRow
              id={`Encounter-Row-${history.msgid || history.roundId}`}
              columns="{'Timestamp': '20%', 'ADT': '25%', 'Patient Status': '15%', 'Unit': '10%', 'Room': '10%', 'Bed': '10%','Census': '10%'}"
              size="large"
              key={history.msgid || history.roundId}
            >
              <NeuLabel slot="Timestamp">
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div>{`${history._date}`}</div>
                  <div>{`${history._time.toString().replace(',', ' ')}`}</div>
                </div>
              </NeuLabel>
              {!history.roundId ? (
                <NeuLabel slot="ADT">
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div>{`${
                      history.msgtype !== null && history.msgtype.includes('^')
                        ? history.msgtype.split('^')[1]
                        : ''
                    }`}</div>
                    <div>{`${history._adtMessage}`}</div>
                  </div>
                </NeuLabel>
              ) : (
                <NeuLabel slot="ADT" onClick={() => gotoRoundPage(history)}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      color: '#206DA3',
                      fontWeight: 700
                    }}
                  >
                    <div>{`${history.msgtype}`}</div>
                    <div>{`${history._adtMessage}`}</div>
                  </div>
                </NeuLabel>
              )}
              <NeuLabel slot="Patient Status">
                {history._patientStatus ? history._patientStatus : '-'}
              </NeuLabel>
              <NeuLabel slot="Unit">{history.pointofcare}</NeuLabel>
              <NeuLabel slot="Room">
                {history.room ? history.room : 'Unknown'}
              </NeuLabel>
              <NeuLabel slot="Bed">
                {history.bed ? history.bed : 'Unknown'}
              </NeuLabel>
              <NeuLabel slot="Census">
                {history._census ? history._census : '-'}
              </NeuLabel>
            </NeuTableRow>
          );
        })}
      </NeuTableBody>
      <PaginationContainer>
        <NeuPaginator
          total={
            parseInt(String(encounterData?.history.length), 10) +
            parseInt(String(roundHistory?.length), 10)
          }
          rowsPerPage={pageSize}
          onNeuChange={e => pageNumberChanged(e.detail.pageNumber)}
        />
      </PaginationContainer>
    </NeuTable>
  );
};

export default EncountersHistoryTable;
