/**
 * External Imports
 */
import { FC, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { orderBy } from 'lodash';
import { format } from 'date-fns';

import { NeuContainer, NeuAvatar, NeuLabel } from '@neutron/react';
import { useAnalyticsApi } from '@shared-web-analytics/react/dist';

/**
 * Internal Imports
 */
import Table from '../../shared/table';
import { logRoundProfile } from '../../../utils/analyticsHelpers';
import {
  date,
  isNewHireStrategy,
  handleBlobPicUrl,
  userProfilePicUrl
} from '../../../utils/helpers';
import { RootState } from '../../../redux/store';
import { clearEmployeeRoundHistory } from '../../../redux/actions/Report.action';
import { clearTasksByEmployeeId } from '../../../redux/actions/Task.action';
import {
  getEmployeeNotes,
  setSelectedEmployee
} from '../../../redux/actions/User.action';
import overdueLogo from '../../../assets/images/alarm.png';
import defaultImage from '../../../assets/images/defaultImage.png';
/**
 * Global Type Definition Imports
 */
import { Employee } from '../../../config/interfaces';
/**
 * Style Imports
 */
import {
  ToDoRow,
  CompleteRow,
  ToDoLabel,
  ToDoIcon,
  CompleteIcon
} from '../censusTable/CensusTable.styles';
import {
  StyledLabel,
  StyledTableHeading
} from '../../shared/table/Table.styles';
import {
  AvatarDiv,
  IconDiv,
  EmployeeInfoDiv,
  EmployeeAvatar,
  DeptStartContainer,
  NewHireIconContainer,
  StartDateContainer,
  OverdueImg
} from './EmployeesTable.styles';

interface EmployeeTableProps {
  selectedTab: string;
  newHireToDo: Array<Employee>;
  newHireComplete: Array<Employee>;
  toDoRounds: Array<Employee>;
  completedRounds: Array<Employee>;
  searchTerm: string;
  sasToken: string;
  frequency?: string;
  newHireFrequency: number;
}

const EmployeesTable: FC<EmployeeTableProps> = ({
  selectedTab,
  newHireComplete,
  newHireToDo,
  toDoRounds,
  completedRounds,
  searchTerm,
  sasToken,
  frequency,
  newHireFrequency
}) => {
  const dispatch = useDispatch();
  const [newHireCompleteState, setNewHireCompleteState] =
    useState<Employee[]>(newHireComplete);
  const [newHireToDoState, setNewHireToDoState] =
    useState<Employee[]>(newHireToDo);
  const [toDoRoundsState, setToDoRoundsState] =
    useState<Employee[]>(toDoRounds);
  const [completedRoundsState, setCompletedRoundsState] =
    useState<Employee[]>(completedRounds);
  const [isTableEmpty, setIsTableEmpty] = useState(false);
  const [isTableEmptySearch, setIsTableEmptySearch] = useState(false);
  const [isTableEmptyCompleted, setIsTableEmptyCompleted] = useState(false);

  const [sortDir, setSortDir] = useState<'desc' | 'asc'>('asc');
  const [sortKey, setSortKey] = useState<string>('endDueDate');
  const [active, setActive] = useState<string>('Round Due');

  const { logTrackingEvent } = useAnalyticsApi();

  const navigate = useNavigate();

  const openEmployeeProfile = (selectedEmployee: Employee) => {
    dispatch(clearEmployeeRoundHistory());
    dispatch(clearTasksByEmployeeId());
    dispatch(setSelectedEmployee(selectedEmployee));
    dispatch(getEmployeeNotes(selectedEmployee.hcaid));
    logTrackingEvent(logRoundProfile());
    navigate('/employees/profile');
  };

  useEffect(() => {
    switch (selectedTab) {
      case 'Completed':
        {
          const q = new RegExp(searchTerm.trim().replace(/\\/g, ''), 'i');
          const filteredEmployee = completedRounds.filter(
            employee =>
              `${employee.firstName} ${employee.lastName}`.match(q) ||
              employee.hcaid.match(q) ||
              `${employee.lastName} ${employee.firstName}`.match(q)
          );
          setCompletedRoundsState(filteredEmployee);
          if (searchTerm && filteredEmployee.length === 0) {
            setIsTableEmptySearch(true);
            setIsTableEmpty(false);
            setIsTableEmptyCompleted(false);
          } else {
            setIsTableEmptySearch(false);
            setIsTableEmpty(false);
            setIsTableEmptyCompleted(false);
          }
          if (!searchTerm && filteredEmployee.length === 0) {
            setIsTableEmptyCompleted(true);
            setIsTableEmpty(false);
            setIsTableEmptySearch(false);
          } else {
            setIsTableEmptyCompleted(false);
            setIsTableEmpty(false);
          }
        }
        break;
      case 'All':
        {
          const q = new RegExp(searchTerm.trim().replace(/\\/g, ''), 'i');
          const filteredEmployeeCompleted = completedRounds.filter(
            employee =>
              `${employee.firstName} ${employee.lastName}`.match(q) ||
              employee.hcaid.match(q) ||
              `${employee.lastName} ${employee.firstName}`.match(q)
          );
          const filteredEmployeeToDo = toDoRounds.filter(
            employee =>
              `${employee.firstName} ${employee.lastName}`.match(q) ||
              employee.hcaid.match(q) ||
              `${employee.lastName} ${employee.firstName}`.match(q)
          );
          setToDoRoundsState(filteredEmployeeToDo);
          setCompletedRoundsState(filteredEmployeeCompleted);
          if (
            searchTerm &&
            filteredEmployeeCompleted.length + filteredEmployeeToDo.length === 0
          ) {
            setIsTableEmptySearch(true);
            setIsTableEmpty(false);
            setIsTableEmptyCompleted(false);
          } else {
            setIsTableEmptySearch(false);
            setIsTableEmpty(false);
            setIsTableEmptyCompleted(false);
          }

          if (
            !searchTerm &&
            filteredEmployeeCompleted.length + filteredEmployeeToDo.length === 0
          ) {
            setIsTableEmpty(true);
            setIsTableEmptyCompleted(false);
            setIsTableEmptySearch(false);
          } else {
            setIsTableEmpty(false);
            setIsTableEmptyCompleted(false);
          }
        }
        break;
      case 'To Do':
        {
          const q = new RegExp(searchTerm.trim().replace(/\\/g, ''), 'i');
          const filteredEmployeeToDo = toDoRounds.filter(
            employee =>
              `${employee.firstName} ${employee.lastName}`.match(q) ||
              employee.hcaid.match(q) ||
              `${employee.lastName} ${employee.firstName}`.match(q)
          );
          setToDoRoundsState(filteredEmployeeToDo);
          if (searchTerm && filteredEmployeeToDo.length === 0) {
            setIsTableEmptySearch(true);
            setIsTableEmpty(false);
            setIsTableEmptyCompleted(false);
          } else {
            setIsTableEmptySearch(false);
            setIsTableEmpty(false);
            setIsTableEmptyCompleted(false);
          }
          if (!searchTerm && filteredEmployeeToDo.length === 0) {
            setIsTableEmpty(true);
            setIsTableEmptyCompleted(false);
            setIsTableEmptySearch(false);
          } else {
            setIsTableEmpty(false);
            setIsTableEmptyCompleted(false);
          }
        }
        break;
      case 'New Hires':
        {
          const q = new RegExp(searchTerm.trim().replace(/\\/g, ''), 'i');
          const filteredEmployeeNewHiresComplete = newHireComplete.filter(
            employee =>
              `${employee.firstName} ${employee.lastName}`.match(q) ||
              employee.hcaid.match(q) ||
              `${employee.lastName} ${employee.firstName}`.match(q)
          );
          const filteredEmployeeNewHiresToDo = newHireToDo.filter(
            employee =>
              `${employee.firstName} ${employee.lastName}`.match(q) ||
              employee.hcaid.match(q) ||
              `${employee.lastName} ${employee.firstName}`.match(q)
          );
          setNewHireCompleteState(filteredEmployeeNewHiresComplete);
          setNewHireToDoState(filteredEmployeeNewHiresToDo);
          if (
            searchTerm &&
            filteredEmployeeNewHiresComplete.length +
              filteredEmployeeNewHiresToDo.length ===
              0
          ) {
            setIsTableEmptySearch(true);
            setIsTableEmpty(false);
            setIsTableEmptyCompleted(false);
          } else {
            setIsTableEmptySearch(false);
            setIsTableEmpty(false);
            setIsTableEmptyCompleted(false);
          }
          if (
            !searchTerm &&
            filteredEmployeeNewHiresComplete.length +
              filteredEmployeeNewHiresToDo.length ===
              0
          ) {
            setIsTableEmpty(true);
            setIsTableEmptyCompleted(false);
            setIsTableEmptySearch(false);
          } else {
            setIsTableEmpty(false);
            setIsTableEmptyCompleted(false);
          }
        }
        break;
      default:
    }
  }, [searchTerm, selectedTab]);

  useEffect(() => {
    const stateCopyNewHireComplete = [...newHireCompleteState];
    const stateCopyNewHireToDo = [...newHireToDoState];
    const stateCopyToDo = [...toDoRoundsState];
    const stateCopyComplete = [...completedRoundsState];
    const orderBySortDir = sortDir === 'asc' ? 'asc' : 'desc';
    const iterateByLowercase = (employee: Employee) =>
      (employee as any)[sortKey]?.toLowerCase() || '';

    setToDoRoundsState(
      stateCopyToDo.length
        ? orderBy(stateCopyToDo, [iterateByLowercase], [orderBySortDir])
        : stateCopyToDo
    );
    setNewHireCompleteState(
      stateCopyNewHireComplete.length
        ? orderBy(
            stateCopyNewHireComplete,
            [iterateByLowercase],
            [orderBySortDir]
          )
        : stateCopyNewHireComplete
    );
    setNewHireToDoState(
      stateCopyNewHireToDo.length > 0
        ? orderBy(stateCopyNewHireToDo, [iterateByLowercase], [orderBySortDir])
        : stateCopyNewHireToDo
    );
    setCompletedRoundsState(
      stateCopyComplete.length
        ? orderBy(stateCopyComplete, [iterateByLowercase], [orderBySortDir])
        : stateCopyComplete
    );
  }, [sortDir, sortKey]);

  const sortByHeading = (key: string, column: string) => {
    if (key === sortKey && sortDir === 'asc') {
      setSortDir('desc');
    } else {
      setSortDir('asc');
    }
    setActive(column);
    setSortKey(key);
  };

  const cols =
    '{ "Employee":"22%","Department":"20%","Dept Start":"12%","Round Due":"16%","Rounded By":"18%","Last Round":"12%"}';

  const headers: JSX.Element[] = [
    <StyledTableHeading
      id="Employee-Table-Employee-Column"
      key="Employee"
      slot="Employee"
      icon={
        !(active === 'Employee') ? 'asc' : sortDir === 'asc' ? 'desc' : 'asc'
      }
      onClick={() => sortByHeading('fullName', 'Employee')}
      active={active === 'Employee'}
    >
      Employee
    </StyledTableHeading>,
    <StyledTableHeading
      id="Employee-Table-Department-Column"
      key="Department"
      slot="Department"
      icon={
        !(active === 'Department') ? 'asc' : sortDir === 'asc' ? 'desc' : 'asc'
      }
      onClick={() => sortByHeading('dept', 'Department')}
      active={active === 'Department'}
    >
      Department
    </StyledTableHeading>,
    <StyledTableHeading
      id="Employee-Table-Dept-Start-Column"
      key="Dept Start"
      slot="Dept Start"
      icon={
        !(active === 'Dept Start') ? 'asc' : sortDir === 'asc' ? 'desc' : 'asc'
      }
      onClick={() => sortByHeading('effectiveStartDate', 'Dept Start')}
      active={active === 'Dept Start'}
    >
      Dept Start
    </StyledTableHeading>,
    <StyledTableHeading
      id="Employee-Table-Round-Due-Column"
      key="Round Due"
      slot="Round Due"
      icon={
        !(active === 'Round Due') ? 'asc' : sortDir === 'asc' ? 'desc' : 'asc'
      }
      onClick={() => sortByHeading('endDueDate', 'Round Due')}
      active={active === 'Round Due'}
    >
      Round Due
    </StyledTableHeading>,
    <StyledTableHeading
      id="Employee-Table-Rounded-By-Column"
      key="Rounded By"
      slot="Rounded By"
      icon={
        !(active === 'Rounded By') ? 'asc' : sortDir === 'asc' ? 'desc' : 'asc'
      }
      onClick={() => sortByHeading('lastRoundedBy', 'Rounded By')}
      active={active === 'Rounded By'}
    >
      Rounded By
    </StyledTableHeading>,
    <StyledTableHeading
      id="Employee-Table-Last-Round-Column"
      key="Last Round"
      slot="Last Round"
      icon={
        !(active === 'Last Round') ? 'asc' : sortDir === 'asc' ? 'desc' : 'asc'
      }
      onClick={() => sortByHeading('lastRoundDate', 'Last Round')}
      active={active === 'Last Round'}
    >
      Last Round
    </StyledTableHeading>
  ];

  const formattedEmployeeRow = (employee: Employee, type: string) => {
    // const beginningOfYear = date.beginningOfYear();
    return (
      <>
        <EmployeeInfoDiv className="h-100 px-0" slot="Employee">
          <IconDiv>
            {type === 'Completed' ? (
              <CompleteIcon color="blue-80">check</CompleteIcon>
            ) : employee.complianceStatus === 'Overdue' ? (
              <OverdueImg width="20px" alt="overdue logo" src={overdueLogo} />
            ) : (
              <ToDoIcon color="orange-50">circle</ToDoIcon>
            )}
          </IconDiv>
          <AvatarDiv>
            <NeuAvatar
              color="gray-60"
              style={{ height: '40px', width: '40px' }}
            >
              {sasToken ? (
                <EmployeeAvatar
                  src={
                    handleBlobPicUrl(employee.pictureUrl, sasToken) ??
                    userProfilePicUrl(employee.hcaid, sasToken)
                  }
                  alt="Employee Image"
                  onError={(e: any) => {
                    e.currentTarget.src = defaultImage;
                    return null;
                  }}
                />
              ) : (
                <EmployeeAvatar src={defaultImage} alt="Default Image" />
              )}
            </NeuAvatar>
          </AvatarDiv>
          <NeuContainer>
            {type === 'Completed' ? (
              <NeuLabel color="gray-100">
                {employee.firstName} {employee.lastName}
              </NeuLabel>
            ) : (
              <ToDoLabel color="primary-70">
                {employee.firstName} {employee.lastName}
              </ToDoLabel>
            )}
            <StyledLabel>{employee.hcaid}</StyledLabel>
          </NeuContainer>
        </EmployeeInfoDiv>
        <NeuLabel color="gray-90" slot="Department">
          {employee.dept}
        </NeuLabel>
        <DeptStartContainer slot="Dept Start">
          <StartDateContainer>
            {employee.effectiveStartDate &&
            employee.effectiveStartDate !== 'NULL'
              ? date.datePrettyString(employee.effectiveStartDate)
              : 'Unknown'}
          </StartDateContainer>
          <NewHireIconContainer>
            {employee.newHire &&
              isNewHireStrategy(
                employee.effectiveStartDate,
                newHireFrequency
              ) && <CompleteIcon color="blue-30">auto_awesome</CompleteIcon>}
          </NewHireIconContainer>
        </DeptStartContainer>
        <NeuLabel slot="Round Due">
          {employee.newHire
            ? date.datePrettyString(employee.endDueDate)
            : frequency === 'monthly' || !employee.lastRoundDate
            ? date.alphanumericMonthYearDate(employee.startDueDate)
            : `${date.alphanumericMonthYearDate(
                employee.startDueDate
              )} - ${date.alphanumericMonthYearDate(employee.endDueDate)}`}
        </NeuLabel>
        <StyledLabel slot="Rounded By">
          {employee.lastRoundDate &&
          employee.lastRoundedBy &&
          employee.lastRoundedBy !== 'Unknown'
            ? employee.lastRoundedBy
            : employee.lastRoundDate &&
              employee.lastRoundedBy &&
              employee.lastRoundedBy === 'Unknown'
            ? 'Unknown'
            : ''}
        </StyledLabel>
        <NeuLabel slot="Last Round">
          {employee.lastRoundedBy &&
          employee.lastRoundedBy !== 'Unknown' &&
          !employee.lastRoundDate
            ? 'Unknown'
            : employee.lastRoundedBy &&
              employee.lastRoundDate &&
              date.isOrbitTimestamp(employee.lastRoundDate)
            ? format(date.parseDate(employee.lastRoundDate), 'MM/dd/yyyy') // make unknown if name and no date
            : ''}
        </NeuLabel>
      </>
    );
  };
  const formatRows = (employeeRounds: Employee[], type: string) => {
    return employeeRounds.map((employee: Employee) =>
      type === 'Completed' ? (
        <CompleteRow
          id={`Completed-Row-${employee.hcaid}`}
          columns={cols}
          size="large"
          onClick={() => openEmployeeProfile(employee)}
          key={employee.hcaid}
        >
          {formattedEmployeeRow(employee, type)}
        </CompleteRow>
      ) : (
        type === 'To Do' && (
          <ToDoRow
            id={`ToDo-Row-${employee.hcaid}`}
            key={employee.hcaid}
            columns={cols}
            size="large"
            onClick={() => openEmployeeProfile(employee)}
          >
            {formattedEmployeeRow(employee, type)}
          </ToDoRow>
        )
      )
    );
  };
  const toDoRows = formatRows(toDoRoundsState, 'To Do');
  const completeRows = formatRows(completedRoundsState, 'Completed');
  const newHireCompleteRows = formatRows(newHireCompleteState, 'Completed');
  const newHireToDoRows = formatRows(newHireToDoState, 'To Do');

  let rows: (boolean | JSX.Element)[] = [];
  switch (selectedTab) {
    case 'To Do':
      rows = toDoRows;
      break;
    case 'Completed':
      rows = completeRows;
      break;
    case 'New Hires':
      rows = [...newHireToDoRows, ...newHireCompleteRows];
      break;
    case 'All':
      rows = [...toDoRows, ...completeRows];
      break;
    default:
      rows = [...toDoRows, ...completeRows];
      break;
  }

  return (
    <Table
      cols={cols}
      headers={headers}
      rows={rows}
      isTableEmpty={isTableEmpty}
      isTableEmptySearch={isTableEmptySearch}
      isTableEmptyCompleted={isTableEmptyCompleted}
      customClass="employee-list-table"
    />
  );
};
const mapReduxStateToProps = (state: RootState) => ({
  sasToken: state.ConfigReducer.sasToken
});

export default connect(mapReduxStateToProps)(EmployeesTable);
