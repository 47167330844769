import { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  NeuButton,
  NeuContainer,
  NeuImage,
  NeuInput,
  NeuLabel
} from '@neutron/react';

import { RootState } from '../../redux/store';
import { Authenticate } from '../../services/AUTH/utils';
import { handleLastPath } from '../../utils/lastPath';

import { BrandLabel, LoginContainer, LoginForm } from './Login.styles';

import { AuthUserProps } from '../../config/interfaces';

import hcaStackedLogo from '../../assets/images/HCA_stacked.jpg';
import orbitLogoOrangeWithName from '../../assets/images/orbit_orange_logo.png';
import Loading from '../../components/shared/Loading';

interface ILoginProps {
  authorizedUser: AuthUserProps;
  authRoles: string[];
  isAuthorized: boolean;
}

const Login: FC<ILoginProps> = ({
  authorizedUser,
  authRoles,
  isAuthorized
}) => {
  const [login34, setLogin34] = useState('');
  const { pathname, search } = useLocation();

  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get('code');

  const navigate = useNavigate();

  const onChangeEvent = (e: CustomEvent<KeyboardEvent>) => {
    const { value } = e.target as HTMLInputElement;
    setLogin34(value);
  };

  const handleAuthentication = () => {
    if (isAuthorized) {
      return handleLastPath(authRoles, navigate);
    }
    return Authenticate(login34);
  };

  useEffect(() => {
    if (pathname.includes('task/complete')) {
      window.sessionStorage.setItem(
        'task',
        new URLSearchParams(search).get('i') ?? ''
      );
    }
    return undefined;
  }, []);

  useEffect(() => {
    if (Object.keys(authorizedUser).length > 0) {
      const storedDeepLinkTaskId = sessionStorage.getItem('task');
      if (storedDeepLinkTaskId) {
        navigate(`/task/complete?i=${storedDeepLinkTaskId}`);
      } else {
        handleLastPath(authRoles, navigate);
      }
    }
  }, [authorizedUser]);

  return process.env.REACT_APP_ENV !== 'production' &&
    !code &&
    !isAuthorized &&
    Object.keys(authorizedUser).length === 0 ? (
    <LoginContainer id="Login-page" className="w-100 h-100">
      <NeuImage
        id="Orbit-Logo"
        className="ml-5 mt-5"
        style={{ position: 'absolute' }}
        alt="orbit-logo-orange"
        src={hcaStackedLogo}
      />
      <NeuContainer className="h-100 justify-content-center align-items-center">
        <LoginForm>
          <NeuContainer className="mb-4 flex-row align-items-center">
            <NeuImage alt="orbit-logo-orange" src={orbitLogoOrangeWithName} />
            <BrandLabel className="ml-4" color="primary-100">
              Orbit
            </BrandLabel>
          </NeuContainer>
          <NeuLabel>3/4 ID</NeuLabel>
          <NeuInput
            id="Login-Input-Box"
            className="mt-1"
            value={login34}
            onNeuInput={e => {
              onChangeEvent(e);
            }}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                handleAuthentication();
              }
            }}
          />
          <br />
          <NeuButton
            id="Login-Button"
            full
            onClick={() => {
              handleAuthentication();
            }}
          >
            Login
          </NeuButton>
        </LoginForm>
      </NeuContainer>
    </LoginContainer>
  ) : (
    <Loading centered />
  );
};

const mapReduxStateToProps = (state: RootState) => ({
  authorizedUser: state.AuthorizedUser.authorizedUser,
  authRoles: state.AuthorizedUser.authRoles,
  isAuthorized: state.AuthorizedUser.isAuthenticated
});

export default connect(mapReduxStateToProps)(Login);
