export const qTags = [
  'Able Round',
  'LOCKED',
  'REQUIRED',
  'REQUIRED-ALL',
  'AmbSurg',
  'OPTT',
  'Emergency',
  'What to Expect',
  'June 2022',
  'Arrival',
  'Safety',
  'BH',
  'Observations',
  'Environment',
  'EMP - Info',
  'EMP - Followup',
  'EMP - Covid',
  'EMP - Retention',
  'EMP - Safety',
  'EMP - Recognition',
  'EMP - Standard',
  'EMP - 90 Day',
  'EMP - Recruitment',
  'EMP - 30 Day',
  'EMP - Support',
  'EMP - Feedback',
  'EMP - January 2023',
  'EMP - 60 Day',
  'Informed',
  'Inpatient',
  'NICU',
  'Rehab',
  'Trust',
  'Confidence',
  'Provider',
  'Courtesy',
  'POC',
  'AIDET',
  'Communication Board',
  'Preferred Name',
  'ED',
  'Discharge',
  'Transition of Care',
  'Finance',
  'IP Rehab',
  'Patient Preference',
  'Teamwork',
  'Listening',
  'Attention To Needs',
  'Discharge Planning',
  'BSSR',
  'Included in Decisions',
  'Explanation',
  'MIT',
  'Questions Answered',
  'Cleanliness',
  'Closing',
  'Feedback',
  'Pain',
  'Comfort',
  'Family/Friends',
  'Addressed Needs',
  'Informed about Delays',
  'Information',
  'BHU',
  'Compassionate',
  'Caring',
  'Recognition',
  'Courtesy/Respect',
  'Emotional Needs',
  'Pediatrics',
  'BHU Discharge',
  'BHU Explanation',
  'Kept Informed',
  'Nurse',
  'Provider',
  'Privacy',
  'Introduction',
  'Medication',
  'Side Effects',
  'MomBaby',
  'Involved',
  'Clinical',
  'Registration',
  'Personal Connection',
  'Goals',
  'Quiet',
  'Call Light',
  'Responsiveness',
  'Safety Rounds',
  'Sensitive to Needs',
  'Coaching',
  'Comments',
  'General',
  'Staff'
];
