/**
 * External Imports
 */
import { FC, RefObject, useEffect, useRef, useState } from 'react';
import {
  NeuIcon,
  NeuButton,
  NeuOption,
  NeuLabel,
  NeuTextarea,
  NeuCheckbox,
  NeuInput
} from '@neutron/react';
import {
  NeuInputChangeEventDetail,
  NeuTextareaChangeEventDetail
} from '@neutron/core';
/**
 * Internal Imports
 */
import MultiSelectDropdown from '../../../MultiSelect/MultiSelectDropdown';
import { Dropdown } from '../../../shared/dropdowns';
/**
 * Global Type Definition Imports
 */
import {
  ConditionListProp,
  QuestionPartProp
} from '../../../../config/interfaces';
/**
 * Style Imports
 */
import {
  QuestionPartCard,
  QuestionPartHeader,
  QuestionPartContents
} from '../AddQuestionModal.styles';

interface IOptionState {
  flag: boolean;
  charge: boolean;
}

interface Props {
  editQuestion: boolean;
  roundingType: string;
  part: QuestionPartProp;
  manuallyChangedOptions: RefObject<Record<string, boolean>>;
  conditionsList: ConditionListProp[];
  moveUpDown: (id: string, updown: string) => void;
  removeQuestionPart: (id: string) => void;
  handleQuestionPromptChange: (
    id: string,
    e: CustomEvent<NeuTextareaChangeEventDetail>
  ) => void;
  addConditionToQuestionPart: (
    id: string,
    conditions: ConditionListProp[]
  ) => void;
  addToQuestionPartConfig: (id: string, key: string, value: string) => void;
  handleMustShowFlag: (
    id: string,
    e: React.BaseSyntheticEvent<MouseEvent>
  ) => void;
  updateMaxMin: (
    id: string,
    maxmin: string,
    e: CustomEvent<NeuInputChangeEventDetail>
  ) => void;
  changeOptionTitle: (
    questionPartId: string,
    optionId: string,
    title: string
  ) => void;
  changeOptionValue: (
    questionPartId: string,
    optionId: string,
    value: string
  ) => void;
  addFlagToAnswer: (
    questionPartId: string,
    optionId: string,
    flag: string
  ) => void;
  handleChargeChange: (partId: string, optionId: string, e: string) => void;
  closeAnswer: (id: string) => void;
  addAnswer: (questionPartId: string) => void;
}

const chargeOptions = ['positive', 'negative'];

const QuestionPart: FC<Props> = ({
  editQuestion,
  roundingType,
  part,
  manuallyChangedOptions,
  conditionsList,
  moveUpDown,
  removeQuestionPart,
  handleQuestionPromptChange,
  addConditionToQuestionPart,
  addToQuestionPartConfig,
  handleMustShowFlag,
  updateMaxMin,
  changeOptionTitle,
  changeOptionValue,
  addFlagToAnswer,
  handleChargeChange,
  closeAnswer,
  addAnswer
}) => {
  const manuallyChangedRef = manuallyChangedOptions;
  const [defaultSelection, setDefaultSelection] = useState<{}[]>([]);
  const [openStates, setOpenStates] = useState<IOptionState[]>([]);
  const focusedValueField = useRef<string | null>(null);

  useEffect(() => {
    setOpenStates(
      part.options.map(() => ({
        flag: false,
        charge: false
      }))
    );
  }, [part.options.length]);

  useEffect(() => {
    if (editQuestion) {
      setDefaultSelection(
        part.questionCondition.map((val: string) => {
          return { value: val };
        })
      );
    }
  }, [editQuestion, part]);

  return (
    <QuestionPartCard
      style={{ width: 'auto', height: 'auto', marginBottom: 10 }}
    >
      <QuestionPartContents>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <QuestionPartHeader>{part.questionType}</QuestionPartHeader>
          <div
            style={{
              color: '#7C7C7C',
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'row',
              fontWeight: '700',
              fontSize: '12',
              marginLeft: 15,
              cursor: 'pointer'
            }}
            onClick={() => {
              moveUpDown(part.questionPartId, 'up');
            }}
            aria-hidden="true"
          >
            <NeuIcon small left>
              arrow_upward
            </NeuIcon>
            <div style={{ marginLeft: 5 }}>Move Up</div>
          </div>
          <div
            style={{
              color: '#7C7C7C',
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'row',
              fontWeight: '700',
              fontSize: '12',
              marginLeft: 5,
              cursor: 'pointer'
            }}
            onClick={() => {
              moveUpDown(part.questionPartId, 'down');
            }}
            aria-hidden="true"
          >
            <NeuIcon small left>
              arrow_downward
            </NeuIcon>
            <div style={{ marginLeft: 5 }}>Move Down</div>
          </div>
          <div
            style={{
              color: '#2075AD',
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'row',
              fontWeight: '700',
              fontSize: '12',
              marginLeft: 10,
              cursor: 'pointer'
            }}
            onClick={() => {
              removeQuestionPart(part.questionPartId);
            }}
            aria-hidden="true"
          >
            <NeuIcon small left>
              cancel
            </NeuIcon>
            <div style={{ marginLeft: 5 }}>Remove</div>
          </div>
        </div>
        <NeuLabel
          style={{
            fontWeight: 400,
            fontSize: 14,
            color: '#191919',
            marginTop: 10
          }}
        >
          Question prompt
        </NeuLabel>
        <NeuTextarea
          id="Prompt-Text-Area"
          placeholder=""
          inputmode="search"
          enterkeyhint="search"
          spellCheck
          style={{ width: '97%' }}
          value={part.question}
          onNeuChange={(e: CustomEvent<NeuTextareaChangeEventDetail>) => {
            handleQuestionPromptChange(part.questionPartId, e);
          }}
        />
        <NeuLabel
          style={{
            fontWeight: 400,
            fontSize: 14,
            color: '#191919',
            marginTop: 10
          }}
        >
          Conditions
        </NeuLabel>
        <div style={{ width: '100%' }}>
          <MultiSelectDropdown
            options={conditionsList}
            defaultselection={defaultSelection}
            selected={(e: ConditionListProp[]) => {
              addConditionToQuestionPart(part.questionPartId, e);
            }}
            id="questionConditions"
            showArrow
            searchable={false}
            chipsInside={false}
            placeholderText="Select one or more..."
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 5 }}>
          <NeuCheckbox
            onClick={e => handleMustShowFlag(part.questionPartId, e)}
          />
          <NeuLabel style={{ fontWeight: 400, fontSize: 14, marginLeft: 5 }}>
            Must show?
          </NeuLabel>
        </div>
        {part?.questionType?.toLowerCase() === 'scale' && (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div
              style={{ display: 'flex', flexDirection: 'column', width: 194 }}
            >
              <NeuLabel
                style={{
                  fontWeight: 400,
                  fontSize: 14,
                  color: '#191919',
                  marginTop: 10
                }}
              >
                Min
              </NeuLabel>
              <NeuInput
                disabled
                type="number"
                onNeuChange={(e: CustomEvent<NeuInputChangeEventDetail>) => {
                  updateMaxMin(part.questionPartId, 'min', e);
                }}
                value={1} // hard-coding for now - scale is set to 1-10
              />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginLeft: 10,
                width: 194,
                color: '#373737'
              }}
            >
              <NeuLabel
                style={{
                  fontWeight: 400,
                  fontSize: 14,
                  color: '#191919',
                  marginTop: 10
                }}
              >
                Max
              </NeuLabel>
              <NeuInput
                disabled
                type="number"
                onNeuChange={(e: CustomEvent<NeuInputChangeEventDetail>) => {
                  updateMaxMin(part.questionPartId, 'max', e);
                }}
                value={10} // hard-coding for now - scale is set to 1-10
              />
            </div>
          </div>
        )}
        {part?.questionType?.toLowerCase() === 'aggregate' && (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: 140,
                color: '#373737'
              }}
            >
              <NeuLabel
                style={{
                  fontWeight: 400,
                  fontSize: 14,
                  color: '#191919',
                  marginTop: 10
                }}
              >
                Maximum answers
              </NeuLabel>
              <NeuInput
                type="number"
                value={part?.config.maxAnswers}
                onNeuChange={e => {
                  addToQuestionPartConfig(
                    part.questionPartId,
                    'maxAnswers',
                    String(e.detail.value)
                  );
                }}
              />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginLeft: 10,
                width: 140
              }}
            >
              <NeuLabel
                style={{
                  fontWeight: 400,
                  fontSize: 14,
                  color: '#191919',
                  marginTop: 10
                }}
              >
                Aggregation value
              </NeuLabel>
              <NeuInput
                type="text"
                value={part?.config.aggregationValue}
                onNeuChange={e => {
                  addToQuestionPartConfig(
                    part.questionPartId,
                    'aggregationValue',
                    String(e.detail.value)
                  );
                }}
              />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginLeft: 10,
                width: 300
              }}
            >
              <NeuLabel
                style={{
                  fontWeight: 400,
                  fontSize: 14,
                  color: '#191919',
                  marginTop: 10
                }}
              >
                Value if none selected
              </NeuLabel>
              <NeuInput
                type="text"
                value={part?.config.defaultValue}
                onNeuChange={e => {
                  addToQuestionPartConfig(
                    part.questionPartId,
                    'defaultValue',
                    String(e.detail.value)
                  );
                }}
              />
            </div>
          </div>
        )}
        {part.options.map((option: any, i: number) => {
          return (
            <div
              style={{ display: 'flex', flexDirection: 'row' }}
              key={option.optionId}
            >
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <NeuLabel
                  style={{
                    fontWeight: 400,
                    fontSize: 14,
                    color: '#191919',
                    marginTop: 10
                  }}
                >
                  Answer
                </NeuLabel>
                <div style={{ width: 195 }}>
                  <NeuInput
                    type="text"
                    placeholder="Addressed well"
                    spellcheck
                    onNeuChange={e => {
                      changeOptionTitle(
                        part.questionPartId,
                        option.optionId,
                        String(e.detail.value)
                      );
                    }}
                    value={option.title}
                  />
                </div>
              </div>
              {roundingType === 'validation' && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginLeft: 10
                  }}
                >
                  <NeuLabel
                    style={{
                      fontWeight: 400,
                      fontSize: 14,
                      color: '#191919',
                      marginTop: 10
                    }}
                  >
                    Value
                  </NeuLabel>
                  <div style={{ width: 162 }}>
                    <NeuInput
                      type="text"
                      placeholder="Value"
                      spellcheck
                      onNeuFocus={() => {
                        manuallyChangedRef.current![option.optionId] = true;
                        focusedValueField.current = option.optionId;
                      }}
                      onNeuBlur={() => {
                        if (option.value === option.title) {
                          delete manuallyChangedRef.current![option.optionId];
                        }
                        focusedValueField.current = '';
                      }}
                      onNeuChange={e => {
                        if (focusedValueField.current !== option.optionId) {
                          return;
                        }
                        changeOptionValue(
                          part.questionPartId,
                          option.optionId,
                          String(e.detail.value)
                        );
                      }}
                      value={option.value}
                    />
                  </div>
                </div>
              )}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginLeft: 6
                }}
              >
                <NeuLabel
                  style={{
                    fontWeight: 400,
                    fontSize: 14,
                    color: '#191919',
                    marginTop: 10
                  }}
                >
                  Label
                </NeuLabel>
                <div style={{ width: 93 }}>
                  <Dropdown
                    classes="pl-0 pt-0"
                    name="flag"
                    open={openStates[i]?.flag}
                    placeholder={option.flag ? option.flag : ''}
                    styles={{ minWidth: '113px' }}
                    width="fit-content"
                    setOpen={() => {
                      setOpenStates(
                        openStates.map((s: IOptionState, j: number) => {
                          return i === j
                            ? { ...s, flag: !s.flag }
                            : { ...s, flag: false };
                        })
                      );
                    }}
                  >
                    <NeuOption
                      className={`dropdown-hover-item${
                        option.flag === '' ? ' selected' : ''
                      }`}
                      value=""
                      key={1}
                      onClick={() => {
                        addFlagToAnswer(
                          part.questionPartId,
                          option.optionId,
                          ''
                        );
                      }}
                    >
                      None
                    </NeuOption>
                    {conditionsList.map((condition: ConditionListProp) => (
                      <NeuOption
                        className={`dropdown-hover-item${
                          option.flag === condition.value ? ' selected' : ''
                        }`}
                        value={condition.value}
                        key={condition.id}
                        onClick={() => {
                          addFlagToAnswer(
                            part.questionPartId,
                            option.optionId,
                            condition.value
                          );
                        }}
                      >
                        {condition.value}
                      </NeuOption>
                    ))}
                  </Dropdown>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginLeft: 14
                }}
              >
                <NeuLabel
                  style={{
                    fontWeight: 400,
                    fontSize: 14,
                    color: '#191919',
                    marginTop: 10
                  }}
                >
                  Charge
                </NeuLabel>
                <div style={{ width: 111 }}>
                  <Dropdown
                    classes="pl-0 pt-0"
                    name="charge"
                    open={openStates[i]?.charge}
                    placeholder={option.charge}
                    styles={{ minWidth: '0' }}
                    width="fit-content"
                    setOpen={() => {
                      setOpenStates(
                        openStates.map((s: IOptionState, j: number) => {
                          return i === j
                            ? { ...s, charge: !s.charge }
                            : { ...s, charge: false };
                        })
                      );
                    }}
                  >
                    {chargeOptions.map((type: string) => (
                      <NeuOption
                        className={`dropdown-hover-item${
                          option.charge === type ? ' selected' : ''
                        }`}
                        value={type}
                        key={type}
                        onClick={(e: React.BaseSyntheticEvent<MouseEvent>) => {
                          handleChargeChange(
                            part.questionPartId,
                            option.optionId,
                            e.target.value
                          );
                        }}
                      >
                        {type}
                      </NeuOption>
                    ))}
                  </Dropdown>
                </div>
              </div>
              <NeuIcon
                small
                style={{ cursor: 'pointer' }}
                onClick={() => closeAnswer(option.optionId)}
              >
                close
              </NeuIcon>
            </div>
          );
        })}
        {(part.questionType === 'single' || part.questionType === 'multi') && (
          <NeuButton
            id="Add-Question-Question-Part-Button"
            color="primary"
            size="small"
            onClick={() => addAnswer(part.questionPartId)}
            style={{
              width: 124,
              height: 24,
              marginTop: 10
            }}
          >
            Add Answer
          </NeuButton>
        )}
      </QuestionPartContents>
    </QuestionPartCard>
  );
};

export default QuestionPart;
