import ACTIONS from '../actions/actionType';

import { ActionType, ITemplate } from '../../config/interfaces';

const initialState = {
  masterTemplateList: [],
  pastRoundTemplate: {},
  selectedTemplate: {},
  templateById: {},
  chosenSurvey: {},
  allQuestions: [],
  questionsOfTemplate: [],
  loading: true,
  editQuestion: false,
  selectedQuestionId: '',
  templateTree: [],
  isTemplateTreeLoading: false,
  isQuestionListLoading: false,
  templateActiveInactiveLoader: false,
  createMasterTemplateSuccessFlag: false,
  editMasterTemplateSuccessFlag: false
};

const TemplateReducer = (state = initialState, action: ActionType = {}) => {
  const tempState = { ...state };

  switch (action.type) {
    case ACTIONS.TEMPLATE.GET_TEMPLATE_MASTER_SUCCESS: {
      const alphabetizedTemplates = action.data.sort(
        (a: ITemplate, b: ITemplate) => {
          if (a.name > b.name) {
            return 1;
          }
          if (a.name < b.name) {
            return -1;
          }
          return 0;
        }
      );
      return {
        ...state,
        masterTemplateList: alphabetizedTemplates
      };
    }
    case ACTIONS.TEMPLATE.GET_TEMPLATE_MASTER: {
      return {
        ...state,
        template: action.data
      };
    }
    case ACTIONS.TEMPLATE.CLEAR_CHOSEN_TEMPLATE: {
      return {
        ...state,
        selectedTemplate: {}
      };
    }
    case ACTIONS.TEMPLATE.SET_CHOSEN_TEMPLATE: {
      return {
        ...state,
        selectedTemplate: action.data
      };
    }
    case ACTIONS.TEMPLATE.GET_ROUND_SURVEY: {
      return {
        ...state,
        chosenSurvey: {},
        loading: true
      };
    }
    case ACTIONS.TEMPLATE.GET_ROUND_SURVEY_SUCCESS: {
      return {
        ...state,
        chosenSurvey: action.data,
        loading: false
      };
    }
    case ACTIONS.TEMPLATE.CLEAR_ROUND_SURVEY: {
      return {
        ...state,
        chosenSurvey: {}
      };
    }
    case ACTIONS.TEMPLATE.GET_ALL_QUESTIONS: {
      return {
        ...state,
        isQuestionListLoading: true
      };
    }
    case ACTIONS.TEMPLATE.GET_ALL_QUESTIONS_SUCCESS: {
      return {
        ...state,
        allQuestions: action.data,
        isQuestionListLoading: false
      };
    }

    case ACTIONS.TEMPLATE.SET_QUESTIONS_OF_TEMPLATE: {
      return {
        ...state,
        questionsOfTemplate: action.data
      };
    }

    case ACTIONS.TEMPLATE.GET_TEMPLATE_BY_ID_SUCCESS: {
      return {
        ...state,
        templateById: action.data,
        templateActiveInactiveLoader: false
      };
    }

    case ACTIONS.TEMPLATE.GET_TEMPLATE_BY_ID_FAILURE: {
      return {
        ...state,
        templateById: {},
        templateActiveInactiveLoader: false
      };
    }

    case ACTIONS.TEMPLATE.EDIT_SELECTED_QUESTION: {
      return {
        ...state,
        editQuestion: true,
        selectedQuestionId: action.data
      };
    }
    case ACTIONS.TEMPLATE.EDIT_QUESTION_SUCCESS: {
      return {
        ...state,
        editQuestion: false,
        selectedQuestionId: ''
      };
    }
    case ACTIONS.TEMPLATE.RESET_EDIT_QUESTION: {
      return {
        ...state,
        editQuestion: false,
        selectedQuestionId: ''
      };
    }
    case ACTIONS.TEMPLATE.GET_TEMPLATE_TREE_VIEW: {
      return {
        ...state,
        isTemplateTreeLoading: true
      };
    }
    case ACTIONS.TEMPLATE.GET_TEMPLATE_TREE_VIEW_SUCCESS: {
      return {
        ...state,
        templateTree: action.data,
        isTemplateTreeLoading: false,
        templateActiveInactiveLoader: false
      };
    }

    case ACTIONS.TEMPLATE.GET_TEMPLATE_BY_ID: {
      return {
        ...state,
        templateActiveInactiveLoader: true
      };
    }

    case ACTIONS.TEMPLATE.CLEAR_TEMPLATE_BY_ID: {
      return {
        ...state,
        templateById: {}
      };
    }

    case ACTIONS.TEMPLATE.CREATE_MASTER_TEMPLATE_SUCCESS: {
      return {
        ...state,
        createMasterTemplateSuccessFlag: true
      };
    }

    case ACTIONS.TEMPLATE.EDIT_MASTER_TEMPLATE_SUCCESS: {
      return {
        ...state,
        editMasterTemplateSuccessFlag: true
      };
    }

    case ACTIONS.TEMPLATE.UPDATE_MASTER_TEMPLATE_SUCCESS_FLAGS: {
      return {
        ...state,
        editMasterTemplateSuccessFlag:
          action.data.editMasterTemplateSuccessFlag,
        createMasterTemplateSuccessFlag:
          action.data.createMasterTemplateSuccessFlag
      };
    }

    case ACTIONS.TEMPLATE.GET_TEMPLATE_DOC_SUCCESS: {
      return {
        ...state,
        questionsOfTemplate: action.data
      };
    }
    case ACTIONS.TEMPLATE.GET_ACTIVE_TEMPLATES_SUCCESS: {
      const alphabetizedTemplates = action.data.sort(
        (a: ITemplate, b: ITemplate) => {
          if (a.name > b.name) {
            return 1;
          }
          if (a.name < b.name) {
            return -1;
          }
          return 0;
        }
      );
      return {
        ...state,
        masterTemplateList: alphabetizedTemplates
      };
    }
    default:
      return tempState;
  }
};

export default TemplateReducer;
