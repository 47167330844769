import { call, put, select } from 'redux-saga/effects';
import { getData, postData } from '../../services/API/axios';

import ACTIONS from '../actions/actionType';
import API from '../../services/API';
import { toast } from '../../services/Toast';
import { date } from '../../utils/helpers';
import store from '../store';
import {
  setChosenTemplate,
  setQuestionsOfTemplate,
  updateMasterTemplateSuccessFlags
} from '../actions/Template.action';

// Template sagas
export function* getTemplateMaster(action) {
  const url = `${API.getTemplatesMasterUrl}?condensed=${action.data.condensed}&archived=${action.data.archived}`;
  try {
    const { data, status } = yield call(getData, url);
    if (status === 200) {
      yield put({
        type: ACTIONS.TEMPLATE.GET_TEMPLATE_MASTER_SUCCESS,
        data
      });
    }
  } catch (error) {
    console.log(error);
  }
}

export function* getRoundSurvey(action) {
  const { unlisted, templateConfigId, includeArchived } = action.data;
  try {
    // Select all params data from store
    const { facilityId } = yield select(
      state => state.AuthorizedUser.authorizedUser
    );
    const { facilities } = yield select(state => state.ConfigReducer);

    // TODO: Throwing ref error here with no matching facility
    const division = facilities
      .filter(facility => facility.facilityId === facilityId)[0]
      .division.replace(/%20/g, '+');

    const { pathname } = window.location;

    let queryParamsObj = {
      templateConfigId,
      includeArchived
    };
    if (pathname.includes('patient')) {
      // Build the params object for patient
      const { unitId } = !unlisted
        ? yield select(state => state.UserReducer.selectedPatient)
        : yield select(state => state.UserReducer.unlistedPatientRoundParams);
      queryParamsObj = { ...queryParamsObj, unitId, division, facilityId };
    }
    // Convert params to a string, build url
    // Call the GET /template/roundSurvey endpoint
    const queryParamsStr = new URLSearchParams(queryParamsObj).toString();
    const surveyUrl = `${API.getFullSurveyUrl}?${queryParamsStr}`;
    const { data: templateData, status } = yield call(getData, surveyUrl);

    // If successful response add the specific questions to the survey and
    // add the survey data to the store
    if (status === 200) {
      const surveyData = {
        ...templateData,
        questions: templateData.questions || []
      };
      yield put({
        type: ACTIONS.TEMPLATE.GET_ROUND_SURVEY_SUCCESS,
        data: surveyData
      });
    }
  } catch (err) {
    console.log(err);
    // TODO: Need round survey failure state
  }
}

export function* getAllQuestions() {
  const url = `${API.getQuestionsUrl}`;
  try {
    const { data, status } = yield call(getData, url);
    if (status === 200) {
      yield put({
        type: ACTIONS.TEMPLATE.GET_ALL_QUESTIONS_SUCCESS,
        data
      });
    }
  } catch (error) {
    console.log(error);
  }
}

export function* addNewQuestion(action) {
  const url = `${API.addNewQuestionUrl}`;
  try {
    const { data, status } = yield call(postData, url, action.data);
    if (status === 200) {
      toast('Question added!', 'success', 1000, 500);
      yield put({
        type: ACTIONS.TEMPLATE.ADD_NEW_QUESTION_SUCCESS,
        data
      });
      return yield call(getAllQuestions);
    }
    return toast('Question failed to Post!', 'error', null, 500, true);
  } catch (error) {
    console.log(error);
    return toast('Question failed to Post!', 'error', null, 500, true);
  }
}

export function* editQuestion(action) {
  const url = `${API.addNewQuestionUrl}`;
  try {
    const { data, status } = yield call(postData, url, action.data);
    if (status === 200) {
      toast('Question Updated!', 'success', 1000, 500);
      yield put({
        type: ACTIONS.TEMPLATE.EDIT_QUESTION_SUCCESS,
        data
      });
      return yield call(getAllQuestions);
    }
    return toast('Question failed to Update!', 'error', null, 500, true);
  } catch (error) {
    console.log(error);
    return toast('Question failed to Update!', 'error', null, 500, true);
  }
}

export function* getTemplatesTree(action) {
  const url = `${API.getTemplatesTreeUrl}?userId=${action.data.userId}&templateConfigId=${action.data.templateConfigId}`;
  try {
    const { data, status } = yield call(getData, url);
    if (status === 200) {
      yield put({
        type: ACTIONS.TEMPLATE.GET_TEMPLATE_TREE_VIEW_SUCCESS,
        data
      });
    }
  } catch (error) {
    console.log(error);
  }
}

export function* postTemplateConfig(action) {
  const url = `${API.postTemplateConfigUrl}`;
  try {
    const { data, status } = yield call(postData, url, action.updatedData);
    if (status === 200) {
      yield put({
        type: ACTIONS.TEMPLATE.POST_TEMPLATE_CONFIG_SUCCESS,
        data
      });
      const { templateConfigId } = yield select(
        state => state.TemplateReducer.selectedTemplate
      );
      const { hcaid } = yield select(
        state => state.AuthorizedUser.authorizedUser
      );
      yield call(getTemplatesTree, {
        data: { userId: hcaid, templateConfigId }
      });
    }
  } catch (error) {
    console.log(error);
  }
}

export function* postMasterTemplate(action) {
  store.dispatch(
    updateMasterTemplateSuccessFlags({
      createMasterTemplateSuccessFlag: false,
      editMasterTemplateSuccessFlag: false
    })
  );
  const url = `${API.getTemplatesMasterUrl}`;
  try {
    const { status } = yield call(postData, url, action.data.updatedData);
    if (status === 200) {
      if (action.data.createTemplateFlag) {
        store.dispatch(setChosenTemplate(action.data.updatedData));
        store.dispatch(setQuestionsOfTemplate([]));
        yield put({
          type: ACTIONS.TEMPLATE.CREATE_MASTER_TEMPLATE_SUCCESS,
          action
        });
      } else if (action.data.updateTemplateFlag) {
        yield put({
          type: ACTIONS.TEMPLATE.EDIT_MASTER_TEMPLATE_SUCCESS,
          action
        });
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export function* getTemplateById(action) {
  const url = `${API.getTemplateById}?templateId=${action.data.templateId}`;

  const { hcaid, firstName, lastName } = yield select(
    state => state.AuthorizedUser.authorizedUser
  );
  try {
    const { data, status } = yield call(getData, url);
    if (data && Object.keys(data).length > 0) {
      if (status === 200) {
        yield put({
          type: ACTIONS.TEMPLATE.GET_TEMPLATE_BY_ID_SUCCESS,
          data
        });

        if (action.data.publishToggle) {
          const updatedData = {
            ...data,
            templateLevel: action.data.templateLevel,
            templateLevelId: action.data.templateLevelId,
            isPublished: action.data.isPublished,
            lastUpdated: new Date(),
            lastUpdatedBy: hcaid,
            lastUpdatedByName: `${firstName} ${lastName}`,
            lastUpdatedDisplay: `${date.datePrettier(
              date.now()
            )} ${date.localTimeOfDay(date.now())}`
          };

          if (data.templateLevel === 'master') {
            delete updatedData.templateId;
          }

          yield call(postTemplateConfig, {
            updatedData,
            updateTemplateFlag: true
          });
        }
        if (action.data.updateTemplateConfigFlag) {
          const updatedData = {
            ...data,
            archived: action.data.archived,
            questions: action.data.questions,
            conditions: action.data.conditions,
            isPublished: action.data.isPublished,
            templateLevel: action.data.templateLevel,
            templateLevelId: action.data.templateLevelId,
            lastUpdated: new Date(),
            lastUpdatedBy: hcaid,
            lastUpdatedByName: `${firstName} ${lastName}`,
            lastUpdatedDisplay: `${date.datePrettier(
              date.now()
            )} ${date.localTimeOfDay(date.now())}`
          };

          if (
            data.templateLevel === 'master' &&
            updatedData.templateId === updatedData.templateConfigId
          ) {
            delete updatedData.templateId;
          }

          yield call(postTemplateConfig, {
            updatedData,
            createTemplateFlag: true,
            updateTemplateFlag: false
          });
        } else if (action.data.updateMasterTemplateFlag) {
          const updatedData = {
            ...data,
            archived: action.data.archived,
            questions: action.data.questions,
            conditions: action.data.conditions,
            isPublished: action.data.isPublished,
            templateLevel: action.data.templateLevel,
            templateLevelId: action.data.templateLevelId,
            lastUpdated: new Date(),
            lastUpdatedBy: hcaid,
            lastUpdatedByName: `${firstName} ${lastName}`,
            lastUpdatedDisplay: `${date.datePrettier(
              date.now()
            )} ${date.localTimeOfDay(date.now())}`
          };
          yield call(postMasterTemplate, {
            data: {
              updatedData,
              createTemplateFlag: false,
              updateTemplateFlag: true
            }
          });
        }
      } else {
        yield put({
          type: ACTIONS.TEMPLATE.GET_TEMPLATE_BY_ID_SUCCESS,
          data: {}
        });
      }
    } else {
      yield put({
        type: ACTIONS.TEMPLATE.GET_TEMPLATE_BY_ID_SUCCESS,
        data: {}
      });
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: ACTIONS.TEMPLATE.GET_TEMPLATE_BY_ID_SUCCESS,
      data: {}
    });
  }
}

export function* getTemplateDoc(action) {
  const url = `${API.getTemplateDocUrl}?docId=${action.data.templateKey}`;
  try {
    const { data, status } = yield call(getData, url);
    if (status === 200) {
      const { allQuestions } = yield select(state => state.TemplateReducer);
      const questionsToList =
        data?.questions?.reduce((acc, ques) => {
          const foundQuestionInBank = allQuestions.find(
            q => q.questionId === ques.id
          );
          return foundQuestionInBank
            ? [...acc, { ...ques, ...foundQuestionInBank }]
            : acc;
        }, []) || [];
      yield put({
        type: ACTIONS.TEMPLATE.GET_TEMPLATE_DOC_SUCCESS,
        data: questionsToList
      });
    }
  } catch (error) {
    console.log(error);
  }
}
// Get active patient templates
export function* getActiveTemplates(action) {
  const { archived, condensed, division, facilityId, roundingType, unitId } =
    action.data;
  const url = `${API.getPatientTemplatesUrl}?roundingType=${roundingType}&division=${division}&facility=${facilityId}&unit=${unitId}&condensed=${condensed}&archived=${archived}`;
  try {
    const { data, status } = yield call(getData, url);
    if (status === 200) {
      yield put({
        type: ACTIONS.TEMPLATE.GET_ACTIVE_TEMPLATES_SUCCESS,
        data
      });
    }
  } catch (error) {
    console.log(error);
  }
}
