/**
 * External Imports
 */
import { connect, useDispatch } from 'react-redux';
import { FC, useCallback, useEffect, useState } from 'react';
import { NeuAvatar } from '@neutron/react';
/**
 * Internal Imports
 */
import Question from './question';
import Loading from '../../shared/Loading';
import { createAnyId, date } from '../../../utils/helpers';
import { useDebounceValue } from '../../../utils/debouncers';
import { setCurrentRound } from '../../../redux/actions/Round.action';
import {
  AuthUserProps,
  Employee,
  ITemplate,
  Patient
} from '../../../config/interfaces';
/**
 * Style Imports
 */
import { SurveyContainer } from './Survey.styles';

const { v4: uuidv4 } = require('uuid');

interface ISurveyProps {
  allQuestions: any;
  chosenSurvey: any;
  isNew: boolean;
  isViewOnly: boolean;
  loading: boolean;
  round: any;
  selectedPatient: Patient;
  selectedEmployee: Employee;
  selectedTemplate?: ITemplate;
  selectedTimestamp: string;
  type: string;
  unListedPatientParams: any;
  user: AuthUserProps;
}

const Survey: FC<ISurveyProps> = ({
  allQuestions,
  chosenSurvey,
  isNew,
  isViewOnly,
  round,
  loading,
  selectedPatient,
  selectedEmployee,
  selectedTemplate,
  selectedTimestamp,
  unListedPatientParams,
  type,
  user
}) => {
  const dispatch = useDispatch();
  const [data, setData] = useState<any>({});

  const debouncedData = useDebounceValue(data, 400);

  const updateAggregatePart = useCallback(
    (aggPart: any, questionPartId: string, newAnswer: any, questions: any) => {
      const { aggregationValue = '' } = aggPart.config;
      if (!aggregationValue) {
        return aggPart;
      }
      const { defaultValue = '' } = aggPart.config;
      const newOptions = [];
      const validValues: string[] = [];
      questions.forEach((q: any) => {
        q.questionParts.forEach((part: any) => {
          if (part.questionType === 'single') {
            let newPartValue;
            let optionId;
            if (part.questionPartId === questionPartId) {
              if (newAnswer.value === aggregationValue) {
                newPartValue = part.question;
                optionId = newAnswer?.optionId ?? uuidv4();
                validValues.push(part.question);
              }
            } else if (
              part.selectedAnswer !== undefined &&
              part.selectedAnswer.length > 0 &&
              part.selectedAnswer[0].value === aggregationValue
            ) {
              newPartValue = part.question;
              optionId = part.selectedAnswer?.optionId ?? uuidv4();
              validValues.push(part.question);
            }
            if (newPartValue !== undefined) {
              newOptions.push({
                optionId,
                value: newPartValue,
                title: newPartValue,
                charge: null,
                flag: null
              });
            }
          }
        });
      });
      if (newOptions.length === 0) {
        newOptions.push({
          optionId: uuidv4(),
          value: defaultValue,
          title: defaultValue,
          charge: null,
          flag: null
        });
        validValues.push(defaultValue);
      }

      const filteredAnswers =
        aggPart.selectedAnswer === undefined
          ? []
          : aggPart.selectedAnswer.filter((answerOption: any) => {
              return validValues.includes(answerOption.value);
            });

      return {
        ...aggPart,
        options: newOptions,
        selectedAnswer: filteredAnswers
      };
    },
    []
  );

  useEffect(() => {
    setData({});
    if (Object.keys(chosenSurvey).length > 0) {
      if (!isNew) {
        const questionsWithAggregates: string[] = [];
        const initialData = {
          ...chosenSurvey,
          selectedConditions:
            round.answers?.reduce(
              (conds: string[], ans: any) =>
                ans.answer?.map((a: any) => a.flag).filter((f: string) => f)
                  .length > 0
                  ? [
                      ...conds,
                      {
                        questionId: ans.questionId,
                        flags:
                          ans.answer
                            ?.map((a: any) => a.flag)
                            .filter((f: string) => f) || []
                      }
                    ]
                  : [...conds],
              []
            ) || [],
          // checking if round is still in editable state or view only
          questions: !isViewOnly
            ? chosenSurvey.questions.map((ques: any) => {
                const answerFilter =
                  round.answers?.filter((ans: any) => {
                    return ans.questionId === ques.questionId;
                  }) || [];
                ques.questionParts?.forEach((part: any) => {
                  if (part.questionType === 'aggregate') {
                    questionsWithAggregates.push(ques.questionId);
                  }
                  answerFilter.forEach((answerPart: any) => {
                    if (part.questionPartId === answerPart.questionPartId) {
                      if (answerPart.answer)
                        part.selectedAnswer = answerPart.answer; // eslint-disable-line no-param-reassign
                    }
                  });
                });
                return {
                  ...ques,
                  conditions:
                    answerFilter?.reduce(
                      (acc: any[], ans: any) =>
                        ans.flag
                          ? [
                              ...acc,
                              {
                                questionPartId: ans.questionPartId,
                                flag: ans.answer.reduce(
                                  (flags: string[], a: any) => [
                                    ...flags,
                                    a.flag
                                  ],
                                  []
                                )
                              }
                            ]
                          : [...acc],
                      []
                    ) || []
                };
              })
            : round.answers
                // the reducer only returns an array of the meta data of the questions with ans in the question part
                // [{questionId: string, questionParts:[ans]}]
                .reduce((acc: any[], ans: any) => {
                  // Looking in the acc to see if the questionId already exists because each question can have mulitple question parts
                  const questionIdIndex = acc.findIndex(
                    (q: any) => q.questionId === ans.questionId
                  );
                  // if -1, then questionId is not in the accumulator and need to create a new question object for that questionId
                  if (questionIdIndex === -1) {
                    return [
                      ...acc,
                      // putting the whole ans obj into the questionParts array as there could be more parts (answers) associated to this question
                      {
                        questionId: ans.questionId,
                        questionParts: [ans]
                      }
                    ];
                  }
                  // if questionId already exists then add ans to the questionpart array using the index of found questionId obj
                  acc[questionIdIndex].questionParts.push(ans);
                  return [...acc];
                }, [])
                // filter questions that are not in the question bank anymore
                .filter((obj: any) =>
                  allQuestions.find(
                    (allq: any) => allq.questionId === obj.questionId
                  )
                )
                .map((questionWithAnswerObj: any) => {
                  // getting the full question object from the questionbank using the questionId
                  // because questionWithAnswer is only meta data
                  const questionBankQuestionObj: any =
                    allQuestions.find(
                      (q: any) =>
                        q.questionId === questionWithAnswerObj.questionId
                    ) || null;
                  // if question is found, then we go through each question part of the question
                  // and add the answer from questionWithAnswerObj (if any)
                  questionBankQuestionObj.questionParts?.forEach(
                    // qbqQuestionParts is the question part from the question found in the question bank
                    (qbqQuestionParts: any) => {
                      questionWithAnswerObj.questionParts.forEach(
                        // qwaQuestionParts is the question part from the questionWithAnswerObj
                        (qwaQuestionParts: any) => {
                          if (
                            qbqQuestionParts.questionPartId ===
                              qwaQuestionParts.questionPartId &&
                            qwaQuestionParts.answer
                          )
                            qbqQuestionParts.selectedAnswer = // eslint-disable-line no-param-reassign
                              qwaQuestionParts.answer;
                        }
                      );
                    }
                  );
                  // finding the conditions based on the flags for each answer
                  const conditions = questionWithAnswerObj.questionParts.reduce(
                    (conditionsArr: any, questionPart: any) => {
                      const flags = questionPart.answer.reduce(
                        (flagsArr: any, ans: any) => {
                          return ans.flag
                            ? [
                                ...flagsArr,
                                {
                                  questionPartId: questionPart.questionPartId,
                                  flag: ans.flag
                                }
                              ]
                            : [...flagsArr];
                        },
                        []
                      );
                      return [...conditionsArr, ...flags];
                    },
                    []
                  );
                  return {
                    ...questionBankQuestionObj,
                    conditions: conditions.length ? conditions : []
                  };
                })
        };

        if (questionsWithAggregates.length === 0) {
          setData(initialData);
        } else {
          const newQuestions = initialData.questions.map((ques: any) => {
            return !questionsWithAggregates.includes(ques.questionId)
              ? ques
              : {
                  ...ques,
                  questionParts: [
                    ...ques.questionParts.map((part: any) => {
                      return part.questionType !== 'aggregate'
                        ? part
                        : updateAggregatePart(
                            part,
                            'N/A',
                            '',
                            initialData.questions
                          );
                    })
                  ]
                };
          });

          setData({
            ...initialData,
            questions: newQuestions
          });
        }
      } else {
        setData({
          ...chosenSurvey,
          selectedConditions: [],
          questions: chosenSurvey.questions.map((ques: any) => ({
            ...ques,
            conditions: []
          }))
        });
      }
    }
    if (type === 'patient' && isNew) {
      if (selectedPatient) {
        dispatch(
          setCurrentRound({
            accountNum: selectedPatient.accountNum,
            bed: selectedPatient.bed,
            _source: 'web',
            dept: user.dept,
            division: user.division,
            facilityId: user.facilityId,
            origRoundDate: date.convertDateToUTCTimestamp(new Date()),
            roundDate: selectedTimestamp,
            patientFirstName: selectedPatient.firstName,
            patientId: selectedPatient.urn,
            patientLastName: selectedPatient.lastName,
            patientMRN: selectedPatient.mrn,
            room: selectedPatient.room,
            roundId: createAnyId('round', user.facilityId),
            roundingType: 'patient',
            taskIds: [],
            templateId:
              chosenSurvey?.templateId ?? selectedTemplate?.templateId,
            templateConfigId:
              chosenSurvey?.templateConfigId ??
              selectedTemplate?.templateConfigId,
            templateName: chosenSurvey?.name ?? selectedTemplate?.name,
            title: user.title,
            unit: '', // need to add the unit name here
            unitId: selectedPatient.unitId,
            userFirstName: user.firstName,
            userId: user.hcaid,
            userLastName: user.lastName,
            answers: []
          })
        );
      } else {
        dispatch(
          setCurrentRound({
            accountNum: unListedPatientParams.mrn,
            bed: unListedPatientParams.bed,
            _source: 'web',
            dept: user.dept,
            division: user.division,
            facilityId: user.facilityId,
            origRoundDate: date.convertDateToUTCTimestamp(new Date()),
            roundDate: selectedTimestamp,
            patientFirstName: unListedPatientParams.firstName,
            patientId: unListedPatientParams.mrn,
            patientLastName: unListedPatientParams.lastName,
            patientMRN: unListedPatientParams.mrn,
            room: unListedPatientParams.room,
            roundId: createAnyId('round', user.facilityId),
            roundingType: 'patient',
            taskIds: [],
            templateId:
              chosenSurvey?.templateId ?? selectedTemplate?.templateId,
            templateConfigId:
              chosenSurvey?.templateConfigId ??
              selectedTemplate?.templateConfigId,
            templateName: chosenSurvey?.name ?? selectedTemplate?.name,
            title: user.title,
            unit: '', // need to add the unit name here
            unitId: unListedPatientParams.unitId,
            userFirstName: user.firstName,
            userId: user.hcaid,
            userLastName: user.lastName,
            answers: []
          })
        );
      }
    }
    if (
      (type === 'employee' ||
        type === 'validation' ||
        type === 'csc' ||
        type === 'cned') &&
      isNew
    ) {
      dispatch(
        setCurrentRound({
          dept: user.dept,
          division: selectedEmployee.division,
          employeeDept: selectedEmployee.dept,
          employeeFirstName: selectedEmployee.firstName,
          employeeId: selectedEmployee.hcaid,
          employeeLastName: selectedEmployee.lastName,
          employeeTitle: selectedEmployee.title,
          facilityId: user.facilityId,
          origRoundDate: date.convertDateToUTCTimestamp(new Date()),
          roundDate: selectedTimestamp,
          roundId: createAnyId('round', user.facilityId),
          roundingType: type === 'csc' ? 'csrn' : type,
          taskIds: [],
          templateId: selectedTemplate?.templateId,
          templateConfigId: selectedTemplate?.templateConfigId,
          templateName: selectedTemplate?.name,
          title: user.title,
          userFirstName: user.firstName,
          userId: user.hcaid,
          userLastName: user.lastName,
          _source: 'web',
          answers: []
        })
      );
    }
  }, [chosenSurvey]);

  useEffect(() => {
    if (!isViewOnly) {
      dispatch(
        setCurrentRound({
          ...round,
          roundDate: selectedTimestamp
        })
      );
    }
  }, [selectedTimestamp]);

  useEffect(() => {
    if (Object.keys(debouncedData).length > 0) {
      dispatch(
        setCurrentRound({
          ...round,
          answers: isViewOnly
            ? round.answers || []
            : debouncedData.questions.reduce((acc: any[], q: any) => {
                return acc.concat(
                  q.questionParts.map((questionPart: any) => ({
                    question: questionPart.question,
                    questionId: q.questionId,
                    questionPartId: questionPart.questionPartId,
                    answer:
                      questionPart.selectedAnswer &&
                      questionPart.selectedAnswer.length > 0
                        ? questionPart.selectedAnswer.length > 1 ||
                          (questionPart.selectedAnswer.length === 1 &&
                            questionPart.selectedAnswer[0].value.trim())
                          ? questionPart.selectedAnswer
                          : []
                        : []
                  }))
                );
              }, []),

          completed:
            type === 'patient'
              ? debouncedData?.selectedConditions?.some(
                  (cond: { questionId: string; flags: string[] }) =>
                    cond.flags.indexOf('Able Round') !== -1
                )
              : true
        })
      );
    }
  }, [debouncedData]);

  return (
    <SurveyContainer
      id="Round-Survey"
      className="w-100"
      isLoading={loading}
      isViewOnly={isViewOnly}
    >
      {loading && <Loading />}
      {!loading &&
        data.questions?.length > 0 &&
        data.questions
          // .filter(
          //   (q: any) =>
          //     q.questionCondition.some((condition: string) =>
          //       data.selectedConditions.some(
          //         (c: { questionId: string; flags: string[] }) =>
          //           c.flags.indexOf(condition) !== -1
          //       )
          //     ) || !q.questionCondition.length
          // ) // Commented for now.
          .filter(
            (q: any) =>
              (isViewOnly && data.selectedConditions.length === 0) ||
              q.questionCondition?.length === 0 ||
              (data.selectedConditions &&
                data.selectedConditions.length > 0 &&
                q.questionCondition?.some(
                  (condition: string) =>
                    data.selectedConditions.findIndex(
                      (c: { questionId: string; flags: string[] }) =>
                        c.flags.indexOf(condition) !== -1
                    ) !== -1
                ))
          )
          .map((q: any, i: number) => (
            <div key={q.questionId} style={{ display: 'flex' }}>
              <NeuAvatar
                className="mt-8"
                color="gray-10"
                initials={`${i + 1}`}
              />
              <Question
                data={data}
                q={q}
                setData={setData}
                viewOnly={isViewOnly}
                updateAggregatePart={updateAggregatePart}
              />
            </div>
          ))}
    </SurveyContainer>
  );
};

const mapReduxStateToProps = (state: any) => ({
  allQuestions: state.TemplateReducer.allQuestions,
  user: state.AuthorizedUser.authorizedUser,
  round: state.RoundReducer.currentRound,
  chosenSurvey: state.TemplateReducer.chosenSurvey,
  selectedPatient: state.UserReducer.selectedPatient,
  selectedEmployee: state.UserReducer.selectedEmployee,
  unListedPatientParams: state.UserReducer.unlistedPatientRoundParams
});

export default connect(mapReduxStateToProps)(Survey);
