import { FC } from 'react';
import { connect } from 'react-redux';

import {
  CardContainer,
  CardHeader,
  CardHeaderContainer,
  CardContent,
  CardContentBold,
  CardContentContainer,
  ChevronRightIcon,
  CompetencyIcon,
  CardContentMetaDataContainer,
  CoachingOppLabel,
  CoachingOppsContainer
} from './PastRoundCard.styles';

import { CompetencyImg } from '../../customTables/validationListTable/ValidationListTable.styles';

import competentIcon from '../../../assets/images/competent_icon.png';
import developmentIcon from '../../../assets/images/development_icon.png';
import expertIcon from '../../../assets/images/expert_icon.png';

interface PastRoundCardProps {
  dateTime: string;
  roundId: string;
  name: string;
  userId: string;
  facility: string;
  room: string;
  template?: string;
  unitId: string;
  tag?: string[];
  coachingOpportunities?: string[];
  validationCompetencies: string[];
}

const PastRoundCard: FC<PastRoundCardProps> = ({
  dateTime,
  roundId,
  name,
  userId,
  facility,
  room,
  template,
  unitId,
  tag,
  coachingOpportunities,
  validationCompetencies
}) => {
  const competencyIcon = tag?.includes('Validation_NLR_E')
    ? expertIcon
    : tag?.includes('Validation_NLR_C')
    ? competentIcon
    : tag?.includes('Validation_NLR_D')
    ? developmentIcon
    : '';

  const competencyText = tag?.includes('Validation_NLR_E')
    ? ' - Expert'
    : tag?.includes('Validation_NLR_C')
    ? ' - Competent'
    : tag?.includes('Validation_NLR_D')
    ? ' - Developing'
    : '';

  const hasValidationCompetency =
    Array.isArray(tag) && tag.find(t => validationCompetencies.includes(t));

  return (
    <CardContainer id={`Round-Card-${roundId}`}>
      {hasValidationCompetency && (
        <CardHeaderContainer>
          <CompetencyIcon>
            <CompetencyImg
              width="24"
              alt="competency logo"
              src={competencyIcon}
            />
          </CompetencyIcon>
        </CardHeaderContainer>
      )}
      <ChevronRightIcon>chevron_right</ChevronRightIcon>
      <CardContentContainer>
        <CardContentMetaDataContainer>
          <CardHeader>{dateTime}</CardHeader>
          <CardContent>{`${name} ${userId}`}</CardContent>
          <CardContent>{facility}</CardContent>
          <CardContent>{`${room || ''} ${unitId || ''}`}</CardContent>
          {template && (
            <CardContentBold>
              {template}
              {hasValidationCompetency && competencyText}
            </CardContentBold>
          )}
        </CardContentMetaDataContainer>
        {coachingOpportunities && (
          <CoachingOppsContainer>
            {coachingOpportunities.map(opportunity => (
              <CoachingOppLabel key={opportunity}>
                {opportunity.replace(/\b\w/g, char => char.toUpperCase())}
              </CoachingOppLabel>
            ))}
          </CoachingOppsContainer>
        )}
      </CardContentContainer>
    </CardContainer>
  );
};

const mapReduxStateToProps = (state: any) => {
  return {
    validationCompetencies: state.ConfigReducer.validValidationCompetencies
  };
};

export default connect(mapReduxStateToProps)(PastRoundCard);
