import styled from 'styled-components';
import { NeuIcon } from '@neutron/react';

export const CardContainer = styled.div`
  display: flex;
  padding: 0.5rem 0;
  position: relative;
`;

export const CardHeader = styled.p`
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #124e7f;
`;

export const CardHeaderContainer = styled.div`
  position: absolute;
  top: 0.4em;
  right: 0.5em;
`;

export const CardContentMetaDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding-right: 0.65rem;
`;

export const CardContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 100%;
`;
export const CardContent = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
`;

export const CardContentBold = styled(CardContent)`
  font-weight: bold;
`;

export const ChevronRightIcon = styled(NeuIcon)`
  color: #124e7f;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  margin-left: auto;
  position: absolute;
  right: 0;
  top: 40%;
`;

export const CompetencyIcon = styled(NeuIcon)``;

export const HorizontalDivider = styled.hr`
  margin-top: 15px;
  border-top: 1px solid rgb(224, 224, 224);
  margin-left: -15px;
  margin-right: -15px;
`;

export const CoachingOppsContainer = styled.ul`
  max-width: 12em;
  padding: 0;
  margin: 0;
`;

export const CoachingOppLabel = styled.li`
  font-size: 0.9rem !important;
  left: 1em;
  position: relative;
  word-break: break-word;
`;
