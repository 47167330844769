import styled from 'styled-components';

interface ICSSProps {
  isLoading: boolean;
  isViewOnly: boolean;
}

export const SurveyContainer = styled.section<ICSSProps>`
  height: ${props => (props.isViewOnly ? '' : 'calc(100vh - 389px)')};
  display: flex;
  flex-direction: column;
  overflow: scroll;
  justify-content: ${props => (props.isLoading ? 'center' : 'start')};
  fontsize: 0.75rem;
  padding-left: ${props => (props.isLoading ? '0px' : '40px')};
  margin-top: 20px;
`;
