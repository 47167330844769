/* eslint-disable func-names */
import axios from 'axios';
import jwtDecode from 'jwt-decode';

import store from '../../redux/store';
import {
  clearUser,
  getAuthToken,
  setSessionId
} from '../../redux/actions/Auth.action';

import { requestNewAccessToken } from '../AUTH/utils';

const axiosInstance = axios.create({
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  }
});

const refreshAndRetryRequestQueue = [];
const refreshAndRetryResponseQueue = [];

let isRefreshingRequestToken = false;
let isRefreshingResponseToken = false;

const clearUserFromMemory = () => {
  window.localStorage.removeItem('authToken');
  window.localStorage.removeItem('34');
  sessionStorage.removeItem('sessionId');
  store.dispatch(setSessionId(null));
  store.dispatch(clearUser());
};

class API {
  constructor(instance) {
    this.instance = instance;
  }

  setupInterceptors(navigate) {
    const self = this;
    this.navigate = navigate;
    this.instance.interceptors.request.use(
      async request => {
        if (!request) throw new Error('Invalid request config');

        // Pull accessToken from storage
        let currentAccessToken = window.localStorage.getItem('authToken');
        // If token exists add to the request headers Authorization header else remove said header
        if (currentAccessToken) {
          const decodedToken = jwtDecode(
            currentAccessToken?.split('bearer ')[1]
          );
          const expiration = decodedToken.exp;
          if (expiration && new Date().valueOf() - expiration * 1000 <= 0) {
            request.headers.Authorization = currentAccessToken;
            return request;
          }
          // If a 401 token expired occurs from server start the refresh process unless its already being refreshed
          if (!isRefreshingRequestToken) {
            // Turn on queueing
            isRefreshingRequestToken = true;

            console.info('Refreshing token');

            try {
              const { accessToken: newAccessToken, refreshToken } =
                await requestNewAccessToken();
              const authToken = `bearer ${newAccessToken}`;
              window.localStorage.setItem('authToken', authToken);
              window.localStorage.setItem('refreshToken', refreshToken);
              currentAccessToken = authToken;
              // eslint-disable-next-line no-param-reassign
              request.headers.Authorization = currentAccessToken;

              store.dispatch(getAuthToken());
              // Run through any pending requests and re-submit with the new token
              refreshAndRetryRequestQueue.forEach(
                ({ config, resolve, reject }) => {
                  const updatedConfig = config;
                  updatedConfig.headers.Authorization = currentAccessToken;
                  if (updatedConfig) {
                    self.instance
                      .request(updatedConfig)
                      .then(response => resolve(response))
                      .catch(err => reject(err));
                  }
                }
              );

              // Clear the queue
              refreshAndRetryRequestQueue.length = 0;
              // Re-submit the original failed request
              return request;
            } catch (err) {
              // If any failure occurs from the refresh cycle clear the user from the app and navigate to 401 page
              console.error('Failed refreshing token');
              console.error(err);
              clearUserFromMemory();
              navigate('/401');
            } finally {
              // Turn off queueing
              isRefreshingRequestToken = false;
            }
          }

          // Push any pending async request to the queue if a refresh cycle has started
          return new Promise((resolve, reject) => {
            refreshAndRetryRequestQueue.push({
              config: request,
              resolve,
              reject
            });
          });
        }

        request.headers.delete('Authorization');

        // Clear all user info from app if cant find token and navigate to 401 page
        clearUserFromMemory();

        return Promise.reject(new Error('Invalid token specified'));
      },
      error => {
        // If any errors occur clear all user info from app
        if (error.message.includes('Invalid token specified')) {
          console.error(error.message);
          clearUserFromMemory();
          return navigate('/401');
        }
        console.error(error.message);
        return Promise.reject(error);
      }
    );

    this.instance.interceptors.response.use(
      function (response) {
        return response;
      },
      async function (error) {
        const originalRequest = error.config;
        const { response: failedResponse } = error;
        // Handle error page redirects by checking all api call rejections
        if (failedResponse && failedResponse.config.url.indexOf('api') > 0) {
          if (failedResponse.status === 401) {
            if (!isRefreshingResponseToken) {
              isRefreshingResponseToken = true;
              let currentAccessToken = window.localStorage.getItem('authToken');
              console.log('Refreshing token');
              try {
                const { accessToken: newAccessToken, refreshToken } =
                  await requestNewAccessToken();
                const authToken = `bearer ${newAccessToken}`;
                window.localStorage.setItem('authToken', authToken);
                window.localStorage.setItem('refreshToken', refreshToken);
                currentAccessToken = authToken;
                // eslint-disable-next-line no-param-reassign
                originalRequest.headers.Authorization = currentAccessToken;

                store.dispatch(getAuthToken());
                refreshAndRetryResponseQueue.forEach(
                  ({ config, resolve, reject }) => {
                    const updatedConfig = config;
                    updatedConfig.headers.Authorization = currentAccessToken;
                    self.instance
                      .request(updatedConfig)
                      .then(response => resolve(response))
                      .catch(err => reject(err));
                  }
                );

                // Clear the queue
                refreshAndRetryResponseQueue.length = 0;
                return self.instance(originalRequest);
              } catch (err) {
                console.log('Failed refreshing token', err);
                clearUserFromMemory();
                navigate('/401');
              } finally {
                isRefreshingResponseToken = false;
              }
            }
            return new Promise((resolve, reject) => {
              refreshAndRetryResponseQueue.push({
                config: originalRequest,
                resolve,
                reject
              });
            });
          }
          if (failedResponse.status === 403) {
            // If forbidden statuses are thrown send to error screen
            clearUserFromMemory();
            return navigate('/403');
          }
          if (failedResponse.status === 404) {
            // Only on auth endpoint if not authorized show error screnn
            if (originalRequest.config.url.indexOf('user/auth') > 0) {
              clearUserFromMemory();
              return navigate('/404');
            }
          }
        }
        return Promise.reject(error);
      }
    );
  }

  getInstance() {
    return this.instance;
  }
}

export const apiInstance = new API(axiosInstance);

const instance = apiInstance.getInstance();

const apiCall = ({ method, url, data = {} }) => {
  return instance({ method, url, data })
    .then(response => response)
    .catch(error => error.response);
};

const postData = (url, data) => apiCall({ method: 'post', url, data });

const getData = (url, data) => apiCall({ method: 'get', url, data });

const putData = (url, data) => apiCall({ method: 'put', url, data });

const deleteData = (url, data) => apiCall({ method: 'delete', url, data });

const patchData = (url, data) => apiCall({ method: 'patch', url, data });

const downloadFile = url =>
  instance.request({
    url,
    method: 'get',
    responseType: 'blob'
  });

export {
  getData,
  postData,
  putData,
  deleteData,
  patchData,
  downloadFile,
  instance
};
