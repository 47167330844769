import {
  AuthorizationServiceConfiguration,
  RedirectRequestHandler,
  FetchRequestor,
  LocalStorageBackend,
  BasicQueryStringUtils,
  DefaultCrypto,
  BaseTokenRequestHandler,
  AuthorizationNotifier,
  TokenRequest,
  AuthorizationRequest,
  GRANT_TYPE_AUTHORIZATION_CODE,
  GRANT_TYPE_REFRESH_TOKEN
} from '@openid/appauth';
import jwt_decode from 'jwt-decode';
import store from '../../redux/store';

import { getAuthToken, getUser34 } from '../../redux/actions/Auth.action';

const openIdConnectUrl = process.env.REACT_APP_SSO_URL;
const redirect_uri = process.env.REACT_APP_SSO_REDIRECT_URL;
const client_id = process.env.REACT_APP_SSO_OAUTH_CLIENT_ID;
const scope = process.env.REACT_APP_SSO_OAUTH_SCOPE;
const env = process.env.REACT_APP_ENV;

const fetchConfig = () => {
  return AuthorizationServiceConfiguration.fetchFromIssuer(
    openIdConnectUrl,
    new FetchRequestor()
  )
    .then(response => {
      return response;
    })
    .catch(error => {
      console.log('Something bad happened', error);
    });
};

const tokenRequest = async code => {
  // get authtoken if authorized
  const config = await fetchConfig();

  // Create an auth redirect handler instance
  const newAuthHandler = new RedirectRequestHandler(
    new LocalStorageBackend(),
    new BasicQueryStringUtils(),
    { ...window.location, hash: window.location.search }
  );

  // Setup an auth request listener
  const notifier = new AuthorizationNotifier();

  // Attach listener to the redirect handler
  newAuthHandler.setAuthorizationNotifier(notifier);

  // Set a listener to listen for authorization responses
  notifier.setAuthorizationListener(async (request, response, error) => {
    console.log('Authorization request complete ');
    if (response) {
      const tokenHandler = new BaseTokenRequestHandler(new FetchRequestor());
      let tokenRequest;
      const extras = {};
      if (code) {
        if (request && request.internal) {
          extras.code_verifier = request.internal.code_verifier;
        }
        tokenRequest = new TokenRequest({
          client_id,
          redirect_uri,
          grant_type: GRANT_TYPE_AUTHORIZATION_CODE,
          code,
          extras
        });
      }
      if (tokenRequest) {
        try {
          const tokenResponse = await tokenHandler.performTokenRequest(
            config,
            tokenRequest
          );
          const decodedToken = jwt_decode(tokenResponse.accessToken);
          const authToken = `bearer ${tokenResponse.accessToken}`;
          const refreshToken = tokenResponse.refreshToken;
          let userId = decodedToken.subject.toLowerCase();
          window.localStorage.setItem('authToken', authToken);
          window.localStorage.setItem('refreshToken', refreshToken);
          if (process.env.REACT_APP_ENV === 'production')
            window.localStorage.setItem('34', userId);
          store.dispatch(getAuthToken());
          store.dispatch(getUser34(userId));
        } catch (oError) {
          console.log(oError);
        }
      }
    }
    if (error) {
      return logger.error(`Authorization Error ${error}`);
    }
    return undefined;
  });

  return newAuthHandler.completeAuthorizationRequestIfPossible();
};

const requestNewAccessToken = async () => {
  const tokenRequest = new TokenRequest({
    client_id,
    redirect_uri,
    grant_type: GRANT_TYPE_REFRESH_TOKEN,
    code: undefined,
    refresh_token: window.localStorage.getItem('refreshToken')
  });
  const tokenHandler = new BaseTokenRequestHandler(new FetchRequestor());
  const configuration = await AuthorizationServiceConfiguration.fetchFromIssuer(
    openIdConnectUrl
  );
  const response = await tokenHandler.performTokenRequest(
    configuration,
    tokenRequest
  );

  return response;
};

const Authenticate = async (userId = '') => {
  if (env !== 'production') window.localStorage.setItem('34', userId);

  const config = await fetchConfig();

  const notifier = new AuthorizationNotifier();
  const authorizationHandler = new RedirectRequestHandler(
    new LocalStorageBackend(),
    new BasicQueryStringUtils(),
    window.location,
    new DefaultCrypto()
  );

  notifier.setAuthorizationListener((request, response) => {
    console.log('Authorization request complete');
  });

  authorizationHandler.setAuthorizationNotifier(notifier);

  // create a request
  const request = new AuthorizationRequest({
    client_id,
    redirect_uri,
    scope,
    state: undefined
  });

  // make the authorization request
  authorizationHandler.performAuthorizationRequest(config, request);
};

export { Authenticate, tokenRequest, fetchConfig, requestNewAccessToken };
